.FormSettingDialog .search-container {
  display: flex;
  align-items: center;
}
.FormSettingDialog .IconButton {
  margin-left: 0.5rem;
  margin-bottom: 0.6rem;
}
.FormSettingDialog .Checkbox {
  margin-top: 1.5rem;
}
