.BuildedFormDialogBase .Dialog .modal-content {
  max-height: 80%;
  max-width: 80%;
}

.BuildedFormDialog .form-information {
  padding: 0.6rem 0 1rem 1.5rem;
}
.BuildedFormDialog .decision-check-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}
.BuildedFormDialog .decision-check-container .ErrorMessage {
  display: none;
}
