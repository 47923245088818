.RadiobuttonUI .border {
  padding: 0.5rem;
  /* border: 0.09rem solid var(--input-frame-color); */
  border-radius: 0.2rem;
  background-color: var(--basic-bg-color);
}

.RadiobuttonUI .Radio {
  display: inline-flex;
}

.RadiobuttonUI .Radio label {
  margin-top: 0.2rem;
  margin-right: 1rem;
}