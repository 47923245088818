.Checkbox .main-contents {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.Checkbox .margin {
  margin-right: 1rem;
}

.Checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}

.Checkbox input[type='checkbox']:focus-visible + label::before {
  outline: var(--basic-outline-style);
}

.Checkbox input[type='checkbox'] + label {
  display: none;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
}

.Checkbox input[type='checkbox'] + label::before {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 50%;
  border: 1px solid;
  border-radius: 0.3rem;
  border-color: var(--checkbox-frame-color);
  background-color: var(--checkbox-bg-color);
}

.Checkbox input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 9px;
  margin-top: -9px;
  top: 50%;
  left: 3px;
  transform: rotate(-45deg);
  border-bottom: 3px solid;
  border-left: 3px solid;
  border-color: var(--checkbox-check-color);
}

.Checkbox input[type='checkbox']:disabled + label::before {
  border-color: var(--checkbox-frame-color-disabled);
  background-color: var(--checkbox-bg-color-disabled);
}
.Checkbox input[type='checkbox']:disabled + label::after {
  border-color: var(--checkbox-check-color-disabled);
}
