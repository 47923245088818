
.tenant-invite-confirm-dialog .red {
  color: var(--input-font-color-error);
}

.tenant-invite-confirm-dialog-area li  {
  list-style: none;
}

.tenant-invite-confirm-dialog .message-area {
  padding: 0.5rem 0;
}

.TenantApplyRequestArea .success-area ul {
  padding-left: 0;
}

.TenantApplyRequestArea .success-area .no-list-style {
  list-style: none;
}
