/*
 * ダイアログ関連の共通的なデザインはここに入れる
 * (サプライヤベースで使用するものをとりあえずこちらに入れる)
 */

/* ダイアログのベースサイズ */
.Dialog .modal-content {
  min-height: 4.8rem;
  max-width: 45rem;
  width: 100%;
  min-width: 45rem;
}

.Dialog .modal-content .comment-dialog {
  padding: 1rem 2rem;
}

/* descriptionエリアのあるダイアログのpadding */
.Dialog .modal-content .dialog-with-description {
  padding: 1rem 2rem;
}

.Dialog .modal-content .deadline-change-dialog {
  padding: 1rem;
}

.Dialog .modal-content .value-compare-group .input {
  width: calc(50% - var(--listitem-gap, 2rem) - 2rem);
  flex-shrink: 0;
}

.Dialog .modal-content .value-compare-group .action-icon {
  width: 1.5rem;
  height: 2.5rem;
  padding: 1rem 0.25rem 0;
}

.Dialog .modal-content .requested-deadline {
  margin: 0 0 2rem;
}

.Dialog .modal-content .description-area {
  padding-bottom: 1rem;
}

.Dialog .modal-content .description-area .text-box .text {
  white-space: pre-wrap;
}

.Dialog .modal-content .detail-area {
  padding: 0 1rem;
}

.Dialog .modal-content .detail-area-deep {
  padding: 0 2rem;
}

.Dialog .modal-content .comment-area {
  padding-bottom: 1rem;
}

.Dialog .modal-content .contents-box {
  padding: 2rem;
}

.Dialog .modal-content .button-area {
  display: flex;
}

.Dialog .modal-content .contents-box .word-description-label {
  margin-top: 1.4rem;
}

.Dialog .modal-content .contents-box .no-gap {
  gap: 0.5rem;
}

.Dialog .modal-content .button-area:nth-child(n + 2) {
  margin-top: 2rem;
}

.Dialog .button-area .button:first-child {
  margin: 0 8rem 0 0;
}

.Dialog .modal-content .notification-destination-list-area {
  margin-bottom: 3rem;
}

.Dialog .modal-content .notification-destination-list .items {
  padding: 0.5rem;
  background-color: var(--label-bg-color);
  overflow: scroll;
  height: 7.5rem;
  border-radius: 0.2rem;
}

.Dialog .modal-content .notification-destination-list .items .user-label {
  margin-block-start: 0;
}

.Dialog .modal-content .halfway-action-area {
  margin: 1em 0;
}

.Dialog .modal-content .input-line .min-space {
  gap: 0.2rem;
}

.Dialog .modal-content .input-line .item-group-100.no-space {
  gap: 0 0;
}

.Dialog .modal-content .input-line .item-group-100 .item-group-100.no-space {
  width: inherit;
}

.Dialog .modal-content .input-line.no-space {
  margin-top: 0;
}

/* ボタンとDataboxの間に文字がある場合 */
.Dialog .modal-content .button-area.top-space-8rem {
  margin-top: 8rem;
}

/* ボタンとDataboxの間が上記より広い場合 */
.Dialog .modal-content .button-area.top-space-4rem {
  margin-top: 4rem;
}

/* ボタンとDataboxの間に何もない場合 */
.Dialog .modal-content .button-area.top-space-10rem {
  margin-top: 10rem;
}

.Dialog .modal-content .right-side-label {
  text-align: right;
}

.Dialog .modal-content .bordered-container {
  border: 1px var(--dialog-frame-color) solid;
  padding: 0 2rem 1rem;
}
