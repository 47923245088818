.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 120px;
  height: 120px;
}

/* Loading テキスト */
.spinner span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 12px;
}

/* 1番目のアニメーションの場合 */
.spinner.type1 {
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: #fff rgba(255, 255, 255, 0.12) rgba(255, 255, 255, 0.12);
  /* ローディング要素のアニメーションを指定 */
  -webkit-animation: spinner1_1 1.5s infinite linear forwards;
  animation: spinner1_1 1.5s infinite linear forwards;
}
.spinner.type1 span {
  /* Loading テキストのアニメーションを指定 */
  animation: spinner_loading_text 1.5s infinite linear forwards reverse;
}

/* ローディング要素のアニメーション内容 */
@-webkit-keyframes spinner1_1 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinner1_1 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Loading テキストのアニメーション内容 */
@-webkit-keyframes spinner_loading_text {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes spinner_loading_text {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
