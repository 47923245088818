.FilterArea {
  position: absolute;
  z-index: 4;
  min-width: 32em;
  padding: 1rem 2em;
  background-color: var(--dialog-bg-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 40%;
}

.FilterArea .select-area .title {
  margin: 0;
  color: var(--header-font-color);
  font-weight: 400;
}

.FilterArea .select-area .add {
  display: flex;
  padding: 0.82rem 0 0.12rem;
}

.FilterArea .btn-normal {
  width: 8rem;
  height: 2.8rem;
}

.FilterArea .select-area .rescission {
  padding: 0 0 0.7rem;
}

.FilterArea .btn-area {
  display: flex;
  justify-content: center;
  padding: 1em 0 0;
}

.FilterArea .select-area .button {
  max-width: 8rem;
}

.FilterArea .btn-area .button {
  width: 10.28rem;
  margin: 0 0.4em;
}

.FilterArea .button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.89rem;
  padding: 0;
}

.FilterArea .add .Select {
  margin: 0 1em 0 0;
}

.FilterArea .select-area .single select {
  height: 3rem;
  min-width: 23rem;
}

.FilterArea .Select .single::after {
  top: 1.4rem;
  right: 1.04rem;
}

.FilterArea .scroll-area {
  overflow-y: scroll;
  max-height: 30rem;
}

.FilterArea .select-control {
  padding: 1em;
  border: 1px solid var(--dialog-frame-color);
  border-radius: 0.3em;
  margin-bottom: 1.5rem;
}

.FilterArea .select-control select {
  height: 3rem;
  padding: 0.6rem 0.8rem;
}

.FilterArea .select-control .put-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FilterArea .select-control .put-line>div:last-child {
  padding: 0.8rem 0 0;
}

.FilterArea .select-control .ErrorMessage {
  height: 0;
}

.FilterArea .filter-input.off {
  height: 0px;
}

.FilterArea .filter-input>div {
  width: 100%;
  padding: 0.1rem 0.3rem 0.8rem 0;
}

.FilterArea .filter-input .put-line .aid {
  padding: 1rem 0.2rem 0rem 0.2rem;
}

.FilterArea .filter-input {
  display: flex;
  align-items: center;
}

.FilterArea .filter-input>svg:not(.ErrorMessage) {
  fill: var(--button-bg-color);
  cursor: pointer;
}

.FilterArea .Filterbox .main-contents {
  padding-bottom: 0.5rem;
}

.FilterArea .filter-area-title {
  padding: 0.5em;
  font-weight: bolder;
}

.FilterArea .filter-area-description {
  font-size: var(--basic-font-size);
  padding-bottom: 0.5rem;
}

.FilterArea .filter-area {
  padding: 1em;
}