/* CSS変数定義　参考資料は「デザイン資料[M-Tech Navi]」 */
:root {
  /* 基本色 */
  --basic-bg-color: var(--main-color-white-smoke);
  /* 背景色 */
  /* --basic-bg-color-hover: var(--secondly-color-white-purple); */
  /* 背景色 hover&click 未使用 */

  --basic-font-color: var(--main-color-black);
  /* 文字色 */
  --basic-line-color: var(--main-color-blue);
  /* 罫線 */

  /* フォーカス時などのアウトライン色 */
  --outline-color: var(--main-color-purple);

  --basic-icon-color: var(--main-color-black);
  /* ボタンアイコン */
  /* --basic-icon-color-hover: var(--main-color-dark-purple); */
  /* ボタンアイコン hover&click 未使用 */

  --header-bg-color: var(--main-color-white-smoke);
  /* --header-bg-color-hover: var(--secondly-color-white-purple); */
  /* hover&click 未使用 */

  --header-font-color: var(--main-color-blue);

  --header-icon-color: var(--main-color-blue);
  --header-icon-color-hover: var(--main-color-dark-purple);
  /* hover&click */

  --dialog-bg-color: var(--main-color-white-smoke);
  --dialog-font-color: var(--main-color-black);
  --dialog-frame-color: var(--main-color-gray);
  --dialogheader-bg-color: var(--main-color-blue);
  --dialogheader-font-color: var(--main-color-white-smoke);
  /* --scrollbar-color: var(--main-color-gray); */
  /* 未使用 */
  --basic-bg-color-disabled: rgba(80, 80, 90, 0.65);

  --button-bg-color: var(--main-color-light-blue);
  --button-bg-color-hover: var(--main-color-purple);
  /* hover&click */

  --button-font-color: var(--main-color-white);
  /* --button-font-color-hover: var(--main-color-white-smoke); */
  /* hover&click 未使用 */

  --highbutton-bg-color: var(--main-color-blue);
  --highbutton-bg-color-hover: var(--main-color-dark-purple);
  /*  hover&click */

  --highbutton-font-color: var(--main-color-white);
  --highbutton-font-color-hover: var(--main-color-white);
  /*  hover&click */

  --button-bg-color-disabled: var(--main-color-light-gray);
  --button-font-color-disabled: var(--main-color-white);
  /* --button-image-color-disabled: #000; */
  /* TODO 画像 50&透過で指定する。#000はダミー 未使用 */
  --input-bg-color: var(--main-color-white);
  --input-font-color: var(--main-color-black);
  --input-frame-color: var(--main-color-light-gray);

  --input-icon-color: var(--main-color-light-blue);
  /* --input-icon-color-hover: var(--main-color-purple); */
  /* hover&click 未使用 */

  --input-bg-color-disabled: var(--main-color-white-gray);
  --input-font-color-disabled: var(--main-color-black);
  --input-frame-color-disabled: var(--main-color-light-gray);
  /* --input-bg-color-error: var(--main-color-white); */
  /* 未使用 */
  --input-font-color-error: var(--main-error-color-red);
  --input-frame-color-error: var(--main-error-color-red);
  --input-icon-color-error: var(--main-error-color-red);
  /* --titlelabel-bg-color: var(--main-color-white-smoke); */
  /* 未使用 */
  --titlelabel-font-color: var(--main-color-blue);

  --link-font-color: var(--main-color-light-blue);
  --link-font-color-hover: var(--main-color-purple);
  /* hover&click */

  /* --sideber-bg-color: var(--main-color-white-blue); */
  /* 未使用 */
  /* --sideber-bg-color-hover: var(--secondly-color-light-purple); */
  /* hover&click 未使用 */

  /* --sideber-icon-color: var(--main-color-blue); */
  /* ボタンアイコン 未使用 */
  /* --sideber-icon-color-hover: var(--main-color-dark-purple); */
  /* ボタンアイコン hover&click 未使用 */

  --tool-icon-color: var(--main-color-blue);
  /* ツールアイコン */
  --tool-disabled-icon-color: var(--main-color-blue) 5c;
  /* ツールアイコン（不活性時） */
  /* --tool-read-button-bg-color: var(--secondly-color-blue-gray); */
  /* ボタンアイコン 未使用 */

  --footer-bg-color: var(--main-color-white);
  --footer-border-color: rgba(0, 0, 0, 0.16);

  /* 個別色 */
  --label-bg-color: var(--main-color-white-gray);
  /* --label-font-color: var(--main-color-black); */
  /* 未使用 */
  --checkbox-bg-color: var(--main-color-white);
  --checkbox-frame-color: var(--main-color-light-gray);
  --checkbox-check-color: var(--main-color-black);
  --checkbox-bg-color-disabled: var(--main-color-white-gray);
  --checkbox-check-color-disabled: var(--main-color-black);
  --checkbox-frame-color-disabled: var(--main-color-light-gray);
  --pulldown-bg-color: var(--main-color-white);
  --pulldown-font-color: var(--main-color-black);
  --pulldown-frame-color: var(--main-color-light-gray);
  --pulldown-icon-color: var(--main-color-blue);
  /* ボタンアイコン */
  --pulldown-searcharea-bg-color: var(--main-color-white-smoke);
  --pulldown-resultarea-bg-color: var(--main-color-white);
  --pulldown-resultarea-bg-color-hover: var(--secondly-color-white-purple);
  --listheader-bg-color: var(--main-color-blue);
  --listheader-font-color: var(--main-color-white-smoke);

  --record1-bg-color: var(--main-color-white-smoke);
  --record1-bg-color-hover: var(--secondly-color-white-purple);
  /* hover&click 未使用*/

  --record1-font-color: var(--main-color-black);

  --record2-bg-color: var(--main-color-white);
  /* --record2-bg-color-hover: var(--secondly-color-white-purple); */
  /* hover&click 未使用 */

  --record2-font-color: var(--main-color-black);
  --listsum-bg-color: var(--secondly-color-white-purple);
  /* --listsum-font-color: var(--main-color-black); */
  /* 未使用 */
  --group-bg-color: var(--secondly-color-light-purple);
  /* --group-font-color: var(--main-color-blue); */
  /* 未使用 */
  /* --group-line-color: var(--secondly-color-light-purple); */
  /* 未使用 */
  --toast-bg-color: var(--main-color-light-blue);
  --toast-font-color: var(--main-color-white-smoke);
  --errortoast-bg-color: var(--secondly-error-color-orange-red);
  --errortoast-font-color: var(--main-color-white-smoke);
  --warningtoast-bg-color: var(--main-warning-color-orange);
  --warningtoast-font-color: var(--main-color-white-smoke);

  --menu-bg-color: var(--secondly-color-white-blue);
  --menu-bg-color-hover: var(--secondly-color-purple);
  /* hover&click */

  --menu-font-color: var(--main-color-black);
  --menu-font-color-hover: var(--main-color-white-smoke);
  /* hover&click */

  /* --menu-bg-color-selected: var(--main-color-light-blue); */
  /* 未使用 */

  --actionmenu-bg-color: rgba(235, 240, 255, 0.9);
  --actionmenu-bg-color-hover: var(--main-color-purple);
  /* hover&click */

  --actionmenu-line-color: var(--main-color-white-smoke);
  /* 罫線 未使用 */

  --actionmenu-font-color: var(--main-color-black);
  --actionmenu-font-color-disabled: var(--main-color-black) 40;
  --actionmenu-font-color-hover: var(--main-color-white-smoke);
  /* hover&click */

  --popupmenu-bg-color: var(--main-color-white);
  --popupmenu-bg-color-hover: var(--secondly-color-white-blue);
  /* hover&click */

  --popupmenu-font-color: var(--main-color-black);
  /* --popupmenu-font-color-hover: var(--main-color-black); */
  /* hover&click 未使用 */

  --card-bg-color: var(--main-color-white);
  /* --card-font-color: var(--main-color-black); */
  /* 未使用 */
  /* --tag-bg-color: var(--secondly-color-blue-gray); */
  /* 未使用 */
  /* --tag-font-color: var(--main-color-white-smoke); */
  /* 未使用 */

  --deletebutton-bg-color: var(--secondly-color-dark-gray);
  --deletebutton-bg-color-hover: var(--secondly-color-gray);
  /*  hover&click */

  --deletebutton-font-color: var(--main-color-white-smoke);
  /* --deletebutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click 未使用 */

  --backbutton-bg-color: var(--secondly-color-gray);
  --backbutton-bg-color-hover: var(--secondly-color-light-ash-gray);
  /*  hover&click */

  --backbutton-font-color: var(--main-color-white-smoke);
  /* --backbutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click 未使用 */

  --subbutton-bg-color: var(--secondly-color-gray-purple);
  --subbutton-bg-color-hover: var(--secondly-color-light-brown-purple);
  /*  hover&click */

  --subbutton-font-color: var(--main-color-white-smoke);
  /* --subbutton-font-color-hover: var(--main-color-white-smoke); */
  /* hover&click 未使用 */

  /* --alreadyread-bg-color: var(--secondly-color-blue-gray); */
  /* 未使用 */
  /* --alreadyread-bg-color-hover: var(--secondly-color-ash-green); */
  /*  hover&click 未使用 */

  /* --alreadyreadbutton-font-color: var(--main-color-white-smoke); */
  /* 未使用 */
  /* --alreadyreadbutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click 未使用 */

  --sendbutton-bg-color: var(--secondly-color-light-purple);
  /* --sendbutton-bg-color-hover: var(--main-color-white-blue); */
  /*  hover&click 未使用 */

  /* --sendbutton-font-color: var(--main-color-blue); */
  /* 未使用 */
  /* --sendbutton-font-color-hover: var(--main-color-blue); */
  /*  hover&click 未使用 */

  --ssobutton-bg-color: var(--main-color-white);
  /* --ssobutton-bg-color-hover: var(--main-color-white); */
  /* hover&click　未使用 */

  --ssobutton-frame-color: var(--main-color-light-gray);
  /* 枠線 */
  --ssobutton-frame-color-hover: var(--main-color-white-smoke);
  /*  hover&click */

  --ssobutton-font-color: var(--main-color-black);
  /* --ssobutton-font-color-hover: var(--secondly-color-brown-gray); */
  /*  hover&click 未使用 */

  --ssobutton-image-color: #fff;
  /*TODO 画像 100%で指定。#fffはダミー 未使用 */
  --ssobutton-image-color-hover: #000;
  /*　TODO hover&click 50&透過で指定する。#000はダミー 未使用 */

  /* --profilebutton-bg-color: var(--main-color-blue); */
  /* 未使用 */
  /* --profilebutton-bg-color-hover: var(--main-color-dark-purple); */
  /*  hover&click 未使用 */

  /* --profilebutton-font-color: var(--main-color-white-smoke); */
  /* 未使用 */
  /* --profilebutton-font-color-hover: var(--main-color-dark-purple); */
  /*  hover&click 未使用 */

  --listreloadbutton-bg-color: var(--secondly-color-blue-gray);
  /* --listreloadbutton-bg-color-hover: var(--secondly-color-ash-gray); */
  /*  hover&click 未使用 */

  --listreloadbutton-font-color: var(--main-color-white-smoke);
  --listreloadbutton-font-color-hover: var(--main-color-light-gray);
  /*  hover&click 未使用 */

  --downloadbutton-bg-color: var(--main-color-light-blue);
  --downloadbutton-bg-color-hover: var(--main-color-purple);
  /*  hover&click */

  --downloadbutton-font-color: var(--main-color-white-smoke);
  /* --downloadbutton-font-color-hover: var(--main-color-light-gray); */
  /*  hover&click 未使用 */

  /* --pageheaderhmbutton-bg-color: var(--main-color-blue); */
  /* 未使用 */
  /* --pageheaderhmbutton-bg-color-hover: var(--main-color-dark-purple); */
  /*  hover&click 未使用 */

  /* --listheaderbutton-font-color: var(--main-color-white-smoke); */
  /* 未使用 */
  /* --listheaderbutton-font-color-hover: var(--main-color-light-gray); */
  /*  hover&click 未使用 */

  /* --listhmbutton-font-color: var(--main-color-black); */
  /* 未使用 */
  /* --listhmbutton-font-color-hover: var(--main-color-blue); */
  /*  hover&click 未使用 */

  /* --listhmbutton1-bg-color: var(--main-color-white-smoke); */
  /* 未使用 */
  /* --listhmbutton1-bg-color-hover: var(--secondly-color-white-purple); */
  /*  hover&click 未使用 */

  /* --listhmbutton2-bg-color: var(--main-color-white); */
  /* 未使用 */
  /* --listhmbutton2-bg-color-hover: var(--secondly-color-white-purple); */
  /*  hover&click 未使用 */

  --accent-bg-color: var(--secondly-color-white-blue);
  --accent-font-color: var(--main-color-blue);
  --accent-icon-color: var(--main-color-light-blue);
  /* アイコン */

  /* --loading-icon-color: #000000; */
  /* 未使用 */

  --fileviewer-list-contents-bg-color-selected: var(--secondly-color-purple);
  --fileviewer-list-contents-bg-color: var(--main-color-light-blue);
  --fileviewer-list-contents-bg-color-hover: var(--main-color-purple);
  --fileviewer-list-font-color: var(--main-color-white);
  --fileviewer-list-header-bg-color: var(--main-color-blue);
  /* --fileviewer-icon-color: var(--main-color-white); */
  /* 未使用 */

  --viewer-border-color: var(--main-color-light-gray);
  --viewer-icon-bg-color: var(--secondly-color-dark-gray);
  --viewer-icon-color: var(--main-color-white);
  --viewer-page-icon-color: var(--main-color-black);

  --file-upload-dnd-bg-color: var(--main-color-light-gray);
}
