.AmountBox .right {
  text-align: right;
}

.AmountBox .icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AmountBox .icon-wrapper svg {
  padding: 0 var(--icon-row-padding, 0.2rem);
  height: var(--input-icon-height);
  width: var(--input-icon-width);
}
