/* CSSカラー一次定義 */
:root {
  /* light:19 */
  --main-color-black: #1e1e1e;
  /* light:16 */
  --main-color-blue: #282ba2;
  /* light:8 */
  --main-color-light-blue: #5461d2;
  /* light:7 */
  --main-color-dark-purple: #696ccb;
  /* light:7 */
  --main-color-purple: #a0a7e6;
  /* light:2 dark:2 */
  --main-color-gray: #c8c8c8;
  /* light:10 dark:2 */
  --main-color-light-gray: #dfdfdf;
  /* light:18 dark:4 */
  --main-color-white: #ffffff;
  /* light:2 */
  --main-color-white-gray: #e8e8e8;
  /* light:2 dark:1 */
  --main-color-white-blue: #f4f4fc;
  /* light:30 dark:18 */
  --main-color-white-smoke: #f8f8f8;
  /* light:3 */
  --main-error-color-red: #fe0404;
  /* light:1 dark:1*/
  --main-warning-color-orange: #e59138;
  /* light:4 dark:15 */
  --secondly-color-blue-gray: #606a81;
  /* light:2 */
  --secondly-color-dark-gray: #646464;
  /* light2 dark:3 */
  --secondly-color-gray: #a0a0aa;
  /* light:1 */
  --secondly-color-gray-purple: #b3b4d5;
  /* light:2 */
  --secondly-color-purple: #969ee3;
  /* light:4 */
  --secondly-color-light-purple: #e1e6ff;
  /* dark:1 */
  --secondly-color-light-purple2: #d4d8ed;
  /* light:1 */
  --secondly-error-color-orange-red: #e25f5f;
  /* light:1 */
  --secondly-color-light-ash-gray: #d2d2dc;
  /* light:1 */
  --secondly-color-light-brown-purple: #dbdce9;
  /* light:8 */
  --secondly-color-white-purple: #ebf0ff;
  /* light:3 */
  --secondly-color-white-blue: #edeff7;
  /* light:1 dark:37 */
  --secondly-color-white-gray: #eeeeee;

  /* dark:4 */
  --main-color-crow-black: #242e45;
  /* dark:8 */
  --main-color-blue-brown: #303e4d;
  /* dark:1 */
  --main-color-blue-brown2: #384757;
  /* dark:6 */
  --main-color-green: #3b7a77;
  /* dark:12 */
  --main-color-light-crow-black: #424c63;
  /* dark:1 */
  --main-color-blue-gray: #717a8f;
  /* dark:1 */
  --main-color-blue-gray2: #747e95;
  /* dark:4 */
  --main-color-light-blue-green: #85bbb8;
  /* dark:2 */
  --main-color-light-blue2: #cafbf9;
  /* dark:3 */
  --main-error-color-pink: #fe8989;
  /* dark:1 */
  --secondly-color-night-blue: #30467a;
  /* dark:8 */
  --secondly-color-light-green: #679d9a;
  /* dark:4 */
  --secondly-color-blue-purple: #6a749f;
  /* dark:1 */
  --secondly-color-blue-purple2: #8391ba;
  /* dark:4 */
  --secondly-color-blue-green: #6cb7b4;
  /* dark:2 */
  --secondly-color-blue-green2: #91c4c2;
  /* dark:1 */
  --secondly-color-blue-gray-2: #4c566d;
  /* dark:1 */
  --secondly-color-blue-gray-3: #687188;
  /* dark:1 */
  --secondly-color-blue-gray-4: #5b6988;
  /* dark:3 */
  --secondly-color-light-blue-green: #cfe8e7;
}
