.TaskListView {
  margin: 0;
  position: absolute;
  min-width: 320px;
  top: 2.6em;
  right: 3em;
  background-color: var(--pulldown-bg-color);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  visibility: hidden;
}

.TaskListView.open {
  visibility: visible;
}
