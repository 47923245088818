.CompanyList {
  /* 全体的にコンパクトにとのことでフォントを若干小さめに指定 */
  font-size: 0.9rem;
}

/* ListView を小さくする */
.CompanyList .ListView .list-header-cell {
  height: 2.5rem;
}
.CompanyList .ListView .item {
  padding: 0.3rem 0.5rem;
  height: 2.5rem;
}

.CompanyList .condition-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.CompanyList .current-condition {
  position: relative;
  border: 1px solid var(--input-frame-color);
  margin-top: 1rem;
  width: calc(100% - 11rem);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: var(--basic-small-font-size);
  white-space: pre-wrap;
}
.CompanyList .current-condition > span {
  position: absolute;
  top: -1rem;
  font-size: var(--basic-small-font-size);
}
.CompanyList .current-condition .conditions {
  width: calc(100% - 2rem);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
}
.CompanyList .current-condition .conditions.open {
  overflow: auto;
  height: auto;
  max-height: 6rem;
  white-space: pre-wrap;
}
.CompanyList .current-condition .conditions.open + .toggleCondition {
  transform: rotate(180deg);
}
.CompanyList .current-condition .toggleCondition {
  height: 1.5rem;
  width: 1.5rem;
}
.CompanyList .change-condition {
  margin-top: 1rem;
}
