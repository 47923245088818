
.BusinessUnitDetailDialog .modal-area-container {
  padding: 1rem 2rem 1rem;
}

.BusinessUnitDetailDialog .client-name {
  margin: 1rem 0;
}

.BusinessUnitDetailDialog .client-staffs {
  margin: 2rem 0;
}
