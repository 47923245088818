.ErrorMessage .message {
  color: var(--input-font-color-error);
  font-size: var(--input-error-font-size);
}

.ErrorMessage .icon {
  fill: var(--input-icon-color-error);
  width: 0.7rem;
}

.ErrorMessage .error-contents {
  display: flex;
  align-items: center;
}
