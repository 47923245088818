.DragModeSelector {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 2;
  list-style: none;
  padding-inline-start: 0;
  font-size: 0.75rem;
}

.DragModeSelector li {
  background-color: var(--viewer-icon-bg-color);
  color: var(--viewer-icon-color);
  fill: var(--viewer-icon-color);
  cursor: pointer;
}
.DragModeSelector li.active {
  background-color: var(--basic-line-color);
}
.DragModeSelector .selector-bg {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 1;
}
.DragModeSelector .selector {
  display: flex;
  position: absolute;
  right: 2em;
  top: 2em;
  z-index: 1;
  list-style: none;
  padding-inline-start: 0;
  overflow-x: hidden;
  width: 0;

  transition-duration: 150ms;
  transition-property: width;
}
.DragModeSelector .selector.show {
  z-index: 3;
  width: 4em;
}
