.TaskList .title {
  font-weight: 600;
}

.TaskList .link {
  display: flex;
  align-items: center;
  padding: 0.8em;
  font-size: var(--basic-font-size);
  color: var(--basic-font-color);
  line-height: 1;
}

.TaskList .dd:hover {
  background-color: var(--popupmenu-bg-color-hover);
}

.TaskList .linkIcon {
  margin: auto;
}