.Forum {
  width: 24rem;
}

.Forum .ForumHeader {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--dialog-frame-color);
  margin: 0 1rem;
}
.Forum .ForumHeader .search-box {
  display: flex;
  align-items: center;
  width: 100%;
}

.Forum .ForumHeader .search-box > div:first-child {
  flex-basis: calc(100% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.Forum .ForumHeader .search-box .ErrorMessage {
  display: none; /* バリデーションすることがないため非表示にしておく */
}
.Forum .ForumHeader .search-button {
  margin-top: var(--basic-small-font-size);
}

.Forum .ForumHeader .action-box {
  margin-top: var(--basic-small-font-size);
  display: flex;
}
.Forum .ForumHeader .action-box > hr {
  height: auto;
  margin: 0 0.3rem;
  border: 1px solid var(--dialog-frame-color);
}

.Forum .ForumThreadList {
  height: calc(100vh - 12rem);
  overflow: auto;
  position: relative;
}

.Forum .ForumThreadList.loading::after {
  content: 'Loading…';
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}
