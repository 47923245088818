.DetailInputDialog .Dialog .modal-content {
  width: 80%;
  max-width: 80%;
}

.DetailInputDialog .search-dialog .Dialog .modal-content {
  width: 80%;
  max-width: 80%;
}

.DetailInputDialog .small-dialog .Dialog .modal-content {
  width: 45rem;
  max-width: 45rem;
}

.DetailInputDialog .button-area {
  margin-left: 4rem;
}

.DetailInputDialog .detail-number {
  text-align: right;
}

.DetailInputDialog .FileUploader {
  display: flex;
  padding-top: 0.8rem;
}

.DetailInputDialog .file-line .fileName {
  cursor: pointer;
}

.DetailInputDialog .file-line .gap {
  margin-left: 1rem;
  margin-top: 1rem;
}

.DetailInputDialog .side-search-icon {
  margin-top: 1.5rem;
}

.DetailInputDialog .FileUploader .uploadStatus .actionIcon {
  width: 24px;
  height: 24px;
}

.DetailInputDialog .mt-13 {
  margin-top: 1.3rem;
}

.DetailInputDialog .mt-06 {
  margin-top: 0.6rem;
}