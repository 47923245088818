
.AssetsList .upload-action {
  padding: 2rem;
}

.AssetsList .file-upload {
  padding: 2rem;
}

.AssetsList .Select {
  max-width: 14rem;
}
.AssetsList .Dialog .file-dialog {
  width: 80% !important;
  max-width: 85% !important;
}

.AssetsList .upload-select {
  width: 14rem;
}
.AssetsList .upload-area {
  position: relative;
  background-color: #fff;
}
.AssetsList .upload-area .FileUploader {
  position: relative;
  z-index: 1;
}

.AssetsList .upload-area .FileUploader .uploadStatus {
  left: 2rem;
  position: absolute;
  top: 4rem;
  padding: 0.2rem !important;
}

.AssetsList .upload-area .FileUploaderArea {
  display: inline-flex;
  padding: 1rem 0rem 0rem 2rem;
}

.AssetsList .upload-area .description .joinText {
  margin-left: 1rem;
  margin-right: 1rem;
}

.AssetsList .upload-footer {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0 0;
}
