.Selections {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;

  opacity: 1;
  transition-duration: 150ms;
  transition-property: opacity;
}

.Selections.dragging {
  opacity: 0;
}
