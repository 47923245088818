.WtWorkTaskConfirmation .strong {
  font-weight: bolder;
}

.WtWorkTaskConfirmation .label-margin {
  margin-bottom: 1rem;
}

.WtWorkTaskConfirmation .button-bottom-margin {
  margin-bottom: 1rem;
}

.WtWorkTaskConfirmation .button-margin {
  margin-right: 1rem;
}

.WtWorkTaskConfirmation .input-line {
  padding-left: 1.5rem;
}