.SurveyRequestSenderConfirmation .area-container {
  margin-bottom: 2rem;
}

.SurveyRequestSenderConfirmation .strong {
  font-weight: bolder;
}

.SurveyRequestSenderConfirmation .right {
  text-align: right;
}

.SurveyRequestSenderConfirmation .label-margin {
  margin-bottom: 1rem;
}

.SurveyRequestSenderConfirmation .button-bottom-margin {
  margin-bottom: 1rem;
}

.SurveyRequestSenderConfirmation .button-margin {
  margin-right: 1rem;
}

.SurveyRequestSenderConfirmation .notification-area.close {
  display: none;
}

.SurveyRequestSenderConfirmation .notification-display-area {
  background-color: var(--input-bg-color);
  padding: 1rem;
  min-height: 3rem;
  margin: 1rem 0 1rem 1.5rem;
  color: var(--input-font-color-error);
  white-space: pre-wrap;
}

.SurveyRequestSenderConfirmation .replyActions {
  list-style: none;
  margin: 0;
  padding: 0;
}

.SurveyRequestSenderConfirmation .replyActions li {
  margin-bottom: 1rem;
}

.SurveyRequestSenderConfirmation .mixed-input-line [class*='w-']:has(.CaptionButton) {
  /* ボタンは、エラーメッセージエリア分の余白を下に持つ */
  margin-bottom: calc(1.5rem - 0.8rem);
  align-self: center;
}
