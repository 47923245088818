.CategorizedCheckSamplePage {

}
.CategorizedCheckSamplePage .category-area {
  border: 1px solid #ccc;
  padding: 0.3rem;
}
.CategorizedCheckSamplePage .result-area {
  margin: 1rem 0;
  padding: 1rem;
  color: var(--basic-font-color);
  background-color: var(--input-bg-color-disabled);
  border-radius: 4px;
  box-sizing: border-box;
}
