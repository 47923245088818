.CaptionButton {
  border: none;
  min-width: 10rem;
  padding: 0.7rem;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  white-space: pre-line;
  word-break: break-word;

  transition-duration: 150ms;
  transition-property: background-color, color, fill;
}

.CaptionButton.high {
  background-color: var(--highbutton-bg-color);
  color: var(--highbutton-font-color);
}
.CaptionButton.high:hover {
  background-color: var(--highbutton-bg-color-hover);
  color: var(--highbutton-font-color-hover);
}

.CaptionButton.basic {
  background-color: var(--button-bg-color);
  color: var(--button-font-color);
}
.CaptionButton.basic:hover {
  background-color: var(--button-bg-color-hover);
}

.CaptionButton.sub {
  background-color: var(--subbutton-bg-color);
  color: var(--subbutton-font-color);
}
.CaptionButton.sub:hover {
  background-color: var(--subbutton-bg-color-hover);
}

.CaptionButton.danger {
  background-color: var(--deletebutton-bg-color);
  color: var(--deletebutton-font-color);
}
.CaptionButton.danger:hover {
  background-color: var(--deletebutton-bg-color-hover);
}

.CaptionButton.cancel {
  background-color: var(--backbutton-bg-color);
  color: var(--backbutton-font-color);
}
.CaptionButton.cancel:hover {
  background-color: var(--backbutton-bg-color-hover);
}

.CaptionButton[disabled],
.CaptionButton[disabled]:hover {
  cursor: default;
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
}

.CaptionButton.w-15 {
  width: 15rem;
}
