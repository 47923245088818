.EstimateResultHeader .right {
  text-align: right;
}

.EstimateResultHeader .showAllPicture {
  margin-left: 5rem;
}

.EstimateResultHeader .font-b {
  font-weight: bold;
}
