.ChangeNotificationDialog .Dialog .modal-content {
  width: 80%;
  max-width: 85%;
  min-height: 50%;
}

.ChangeNotificationDialog .change-notification-dialog {
  padding-top: 1rem;
}

.ChangeNotificationDialog .info-area {
  text-align: right;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.ChangeNotificationDialog .scrollable-area {
  overflow: auto;
}

.ChangeNotificationDialog .change-notification-header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
  fill: var(--listheader-font-color);
  min-height: 3rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;
  min-width: fit-content;

  position: sticky;
  top: 0;
  z-index: 1;
}

.ChangeNotificationDialog .change-notification-header>div {
  margin: 0 0.5rem;
}

.ChangeNotificationDialog .change-notification-list {
  list-style: none;
  padding-inline: 0;
  margin-block: 0;
  min-width: fit-content;
  max-height: 50vh;
}

.ChangeNotificationDialog li {
  min-height: 2.5rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;
}

.ChangeNotificationDialog li:nth-child(even) {
  background-color: var(--record1-bg-color);
  color: var(--record1-font-color);
}

.ChangeNotificationDialog li:nth-child(odd) {
  background-color: var(--record2-bg-color);
  color: var(--record2-font-color);
}

.ChangeNotificationDialog li .cell {
  padding: 0.7rem 0.5rem;
  position: relative;
  display: grid;
  white-space: pre-line;
  line-break: anywhere;
}

.ChangeNotificationDialog .button-area {
  margin-left: 2rem;
}
