.Rect {
  position: absolute;
  z-index: 1;
  display: block;
  border-width: 4px;
  border-style: solid;
  border-color: var(--basic-line-color);
  box-sizing: border-box;
  pointer-events: auto;
  opacity: 0.6;
}
.Rect.active {
  opacity: 1;
}
