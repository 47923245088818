.InputButton .message {
  color: var(--input-font-color-error);
  font-size: var(--input-error-font-size);
}

.Button .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 0.7rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
}

.Button .inlineButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.7rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
}

.Button .icon {
  width: 2em;
  padding: 0;
  border: none;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
}

.Button .iconBox {
  display: inline-block;
}

.Button .buttonBox .aid {
  position: relative;
  display: inline-block;
  padding: 1em 1em 1em 3em;
}

.Button .buttonBox img {
  position: absolute;
  top: 0.4em;
  left: 0.4em;
  width: 2em;
}

.Button.inlineButtonBox {
  display: inline-block;
}

.Button .basic {
  background-color: var(--button-bg-color);
  color: var(--button-font-color);
}
.Button .iconBox .basic {
  background-color: var(--button-bg-color);
}
.Button .basic svg {
  fill: var(--button-font-color);
}

.Button .normal {
  border: 0.1em solid var(--disabled-color);
  color: var(--basic-icon-color);
}
.Button .normal svg {
  fill: var(--basic-icon-color);
}

.Button .normalLine {
  border: 0.1em solid var(--disabled-color);
  color: var(--basic-icon-color);
}
.Button .normalLine svg {
  fill: var(--basic-icon-color);
}

.Button .iconBox .normal {
  color: var(--basic-font-color);
}
.Button .iconBox .normal svg {
  fill: var(--basic-font-color);
}

.Button .danger {
  background-color: var(--button-color);
  color: var(--button-font-color);
}
.Button .danger svg {
  fill: var(--button-font-color);
}

.Button .thin {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color);
}
.Button .thin svg {
  fill: var(--button-font-color);
}

.Button .cancel {
  background-color: var(--backbutton-bg-color);
  color: var(--button-font-color);
}
.Button .cancel svg {
  fill: var(--button-font-color);
}

.Button .disabled {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
}

.Button .back::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  height: 0.4rem;
  width: 0.4rem;
  margin: auto;
  border-left: 1px solid var(--button-font-color);
  border-bottom: 1px solid var(--button-font-color);
  transform: rotate(45deg);
}
