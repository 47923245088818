.CategorizedCheckbox:has(input[type='checkbox']:focus-visible),
.CategorizedCheckbox:has(input[type='checkbox']:focus) {
  outline: var(--basic-font-color) dashed 1px;
}
.CategorizedCheckbox input[type='checkbox'] {
  width: 0;
  height:0;
  opacity: 0;
  outline:none;
}
.CategorizedCheckbox input[type='checkbox'] + label {
  display: none;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 4px;
  cursor: pointer;
}
.CategorizedCheckbox.disabled input[type='checkbox'] + label,
.CategorizedCheckbox input[type='checkbox']:disabled + label {
  cursor: default;
}
.CategorizedCheckbox input[type='checkbox'] + label::before {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border: 1px solid;
  border-radius: 0.3rem;
  border-color: var(--checkbox-frame-color);
  background-color: var(--checkbox-bg-color);
}
.CategorizedCheckbox input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 9px;
  top: 0;
  left: 3px;
  transform: rotate(-45deg);
  border-bottom: 3px solid;
  border-left: 3px solid;
  border-color: var(--checkbox-check-color);
}

.CategorizedCheckbox.disabled input[type='checkbox'] + label::before {
  border-color: var(--checkbox-frame-color-disabled);
  background-color: var(--checkbox-bg-color-disabled);
}
.CategorizedCheckbox.disabled input[type='checkbox'] + label::after {
  border-color: var(--checkbox-check-color-disabled);
}

.CategorizedCheckbox.disabled input[type='checkbox']:not(:checked) + label,
.CategorizedCheckbox.discreet input[type='checkbox'] + label,
.CategorizedCheckbox.unmatched input[type='checkbox'] + label {
  color: var(--basic-bg-color-disabled);
}
.CategorizedCheckbox.disabled input[type='checkbox']:not(:checked) + label::after,
.CategorizedCheckbox.discreet input[type='checkbox'] + label::after,
.CategorizedCheckbox.unmatched input[type='checkbox'] + label::after {
  border-color: var(--basic-bg-color-disabled);
}
.CategorizedCheckbox.matched input[type='checkbox']:not(:checked) + label {
  color: var(--basic-font-color);
}
