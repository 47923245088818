.BusinessUnitFacilityDialog .Dialog .modal-content {
  width: 80%;
  max-width: 85%;
}

.business-unit-facility-dialog .Radio {
  margin-top: 1.5rem
}

.business-unit-facility-dialog .Radio label{
  margin-right: 1rem
}
