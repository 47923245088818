.EstimateRequestPlanConfirmation .input-line {
  margin-top: 1.2rem;
}

.EstimateRequestPlanConfirmation .font-b {
  font-weight: bold;
}

.EstimateRequestPlanConfirmation .space-bottom {
  margin-bottom: 3rem;
}
