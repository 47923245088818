.CategorizedCheckList .tip-checkbox-list-container {
  display: flex;
}

.CategorizedCheckList .search-area {
  display: flex;
  align-items: center;
}

.CategorizedCheckList .search-area>div:first-child {
  flex-basis: calc(50% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.CategorizedCheckList .search-button {
  margin-left: 0.3rem;
  margin-bottom: 0.6rem;
}

.CategorizedCheckList .tip-checkbox-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* "全て" チェックボックス分を除いた横幅とする */
  width: calc(100% - 9rem);
}

.CategorizedCheckList.disabled .tip-checkbox-list {
  /* disabled 時は "全て" を表示しないので auto を指定 */
  width: auto;
}

.CategorizedCheckList .CategorizedBranchCheckbox {
  display: inline-block;
  padding: 0.5rem;
  width: 8rem;
  margin-bottom: 1rem;
}

.CategorizedCheckList .CategorizedTipCheckbox {
  padding: 0.5rem;
  margin: 0 1rem 1rem 0;
  width: 10rem;
}

.CategorizedCheckList .link-area {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
}

.CategorizedCheckList .link-button {
  text-decoration: underline;
  color: var(--link-font-color);
  background-color: transparent;
  cursor: pointer;
}