.Viewer .controls > svg {
  fill: var(--viewer-icon-color);
}

.Viewer .controls .arrow-icon {
  fill: var(--viewer-page-icon-color);
}

.Viewer .controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Viewer .scaling-icon-area {
  z-index: 2;
  position: absolute;
  right: 0;
  background-color: var(--viewer-icon-bg-color);
}

.Viewer .scaling-icon-area svg {
  fill: var(--viewer-icon-color);
}

.Viewer .view-area {
  border: 1px solid var(--viewer-border-color);
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  user-select: none;
}
.Viewer .view-area .document {
  width: 100%;
  height: 100%;
  display: block;
}
.Viewer .view-area .document.move,
.Viewer .view-area .document.selection.flip {
  cursor: grab;
}
.Viewer .view-area .document.move:active,
.Viewer .view-area .document.selection.flip:active {
  cursor: grabbing;
}
.Viewer .view-area .document.move.flip,
.Viewer .view-area .document.selection {
  cursor: crosshair;
}
