.TextBox .main-area {
  width: 100%;
  height: var(--input-box-height, 2.5rem);
  display: flex;
  background-color: var(--input-bg-color);
}

.TextBox .main-area:has(input:focus-visible) {
  outline: var(--basic-outline-style);
}

.TextBox input {
  width: 100%;
  flex-grow: 1;
  font-size: var(--basic-font-size);
  border: none;
  outline: none;
  background-color: var(--input-bg-color);
  color: var(--input-font-color);
  padding: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.TextBox svg {
  fill: var(--input-icon-color);
}

.TextBox .icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TextBox .icon-wrapper svg {
  padding: 0 var(--icon-row-padding, 0.2rem);
  height: var(--input-icon-height);
  width: var(--input-icon-width);
}

.TextBox .icon-wrapper-disabled {
  background-color: var(--input-bg-color-disabled);
}

.TextBox input:disabled {
  line-height: 1.4rem;
  background-color: var(--input-bg-color-disabled);
  color: var(--input-font-color-disabled);
  text-overflow: ellipsis;
}

.TextBox input:disabled:hover {
  text-overflow: unset;
}

.TextBox input::placeholder {
  color: var(--input-font-color);
  opacity: 0.5;
}

.TextBox .right {
  text-align: right;
}
