.FormInput .HelpIcon {
  height: auto;
  width: 1rem;
  margin-left: 0.2rem;
}
.FormInput .HelpIcon svg {
  height: auto;
  width: 1rem;
}

.FormInput .topping-label-with-help {
  display: flex;
  align-items: center;
}
