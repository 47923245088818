.OrganizationDetailDialog {
  padding-top: 1rem;
}

.OrganizationDetailDialog .breadcrumb-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.OrganizationDetailDialog .parents-breadcrumb {
  margin: 0 0.5rem 0.5rem;
}
.OrganizationDetailDialog .parents-breadcrumb > span {
  display: inline-flex;
  align-items: center;
}

.OrganizationDetailDialog .parents-breadcrumb > span:not(:last-child)::after {
  content: '>';
  padding: 0 0.5rem;
  font-weight: bold;
  font-size: 0.7rem;
  color: var(--deletebutton-bg-color);
}
