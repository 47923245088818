.InputSelector .label {
  position: relative;
  top: 0.4rem;
  left: 0.3rem;
  background: linear-gradient(
    180deg,
    var(--basic-bg-color) 0%,
    var(--basic-bg-color) 55%,
    var(--input-bg-color) 55%,
    var(--input-bg-color) 100%
  );
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100% - 0.3rem * 2);
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 2;
}

.InputSelector .label .label-text {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.InputSelector.required .label:after {
  content: '*';
  display: inline-block;
  color: var(--input-font-color-error);
}

.InputSelector .HelpIcon {
  height: auto;
  width: 1rem;
  margin-left: 0.2rem;
}

.InputSelector .HelpIcon svg {
  height: auto;
  width: 1rem;
}

.InputSelector .label {
  display: flex;
  align-items: center;
}

.InputSelector .preview {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.2rem;
  justify-content: space-between;
}

.InputSelector .preview-text,
.InputSelector .preview-number,
.InputSelector .preview-float,
.InputSelector .preview-date,
.InputSelector .preview-select,
.InputSelector .preview-selectMultiple,
.InputSelector .preview-toggle,
.InputSelector .preview-two-choice,
.InputSelector .preview-file,
.InputSelector .preview-fileMultiple,
.InputSelector .preview-label {
  background-color: var(--input-bg-color);
  color: var(--input-font-color);
  border: 0.09rem solid var(--input-frame-color);
}

.InputSelector .preview-spacer {
  margin-top: 0.8rem;
  border: 0.09rem dashed var(--input-frame-color);
}

.InputSelector .preview-textarea,
.InputSelector .preview-labelMultiLine {
  background-color: var(--input-bg-color);
  color: var(--input-font-color);
  border: 0.09rem solid var(--input-frame-color);
  height: 4rem;
}

.InputSelector .preview:after {
  content: '';
  position: absolute;
  top: calc(50% - 0.4rem / 2);
  right: 0.5rem;
  width: 0.4rem;
  height: 0.4rem;
  border-top: 0.1rem solid var(--pulldown-icon-color);
  border-left: 0.1rem solid var(--pulldown-icon-color);
  pointer-events: none;
  transform: translateY(-50%) rotate(-135deg);
}

.InputSelector .preview select {
  color: var(--basic-bg-color-disabled);
  height: 100%;
  border: none;
  background: none;
}

.InputSelector .message-area {
  margin: 0.25rem 0.5rem;
  font-size: 0.6rem;
  min-height: 1rem;
  color: var(--input-font-color-error);
}
