.Thread {
  width: 90%;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem 1.2rem;
  border: 1px solid var(--dialog-frame-color);
  background-color: var(--accent-bg-color);
  border-radius: 3px;
  box-sizing: border-box;
  transition-duration: 150ms;
  transition-property: margin, border, box-shadow;
}

.Thread.active {
  margin-left: 0.5rem;
  border: 1px solid var(--accent-font-color);
}

.Thread .thread-info .IconButton {
  font-size: 0.7rem;
  background-color: var(--listreloadbutton-bg-color);
  color: var(--listreloadbutton-font-color);
  fill: var(--listreloadbutton-font-color);
  transition-property: opacity;
}
.Thread .thread-info .IconButton:hover {
  opacity: 0.7;
}

.Thread .header .thread-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2em;
}

.Thread .header .label {
  font-size: 0.7em;
  font-weight: bold;
  color: var(--viewer-icon-color);
  background-color: var(--titlelabel-font-color);
  padding: 0.25em 1em;
  border-radius: 4px;
  display: inline-block;
}

.Thread .header .title {
  color: var(--titlelabel-font-color);
  fill: var(--titlelabel-font-color);
  font-size: 0.9rem;
  width: 100%;
  margin-bottom: 0.4rem;
  word-break: break-all;
}

.Thread .header .title .IconButton {
  width: 1.2em;
  margin-left: 0.3em;
}
.Thread .header .title-edit {
  margin-bottom: 1rem;
}
.Thread .header .title-edit .actions {
  display: flex;
  justify-content: center;
}
.Thread .header .title-edit .actions .CaptionButton {
  width: 6.5em;
  margin-left: 0.5em;
}

.Thread .menu-wrapper {
  position: relative;
}
.Thread .menu-wrapper .IconButton {
  font-size: 0.7rem;
}
.Thread .menu-bg {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 98;
}
.Thread .menu-list {
  position: absolute;
  right: 0;
  min-width: 8em;
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
  z-index: 99;
}
.Thread .menu-list li {
  padding: 0 0.4em;
  line-height: 2.89em;
  display: flex;
  align-items: center;
  background-color: var(--menu-bg-color);
  cursor: pointer;
}
.Thread .menu-list li:hover {
  background-color: var(--menu-bg-color-hover);
}
.Thread .menu-list li svg {
  margin-right: 0.4em;
}

.Thread .comment-list {
  list-style: none;
  padding-inline-start:0;
  margin: 0;
}
.Thread .comment-list li {
  padding: 0.5rem;
}
.Thread .comment-list li:nth-child(odd) {
  background-color: var(--record1-bg-color);
}
.Thread .comment-list li:nth-child(even) {
  background-color: var(--record2-bg-color);
}

.Thread .comment-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9rem;
  height: 3.5em;
  resize: vertical;
}

.Thread .comment-form .action {
  text-align: right;
}

.Thread .comment-form .action .CaptionButton {
  width: 6.5em;
}
