.FormInputFile .uploader {
  width: 100%;
  height: var(--input-box-height, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--input-bg-color);
  border: 1px solid var(--input-frame-color);
  border-radius: 4px;
}

.FormInputFile .uploader:has(input:not(:disabled)) {
  cursor: pointer;
}

.FormInputFile .uploader.readonly,
.FormInputFile .uploader.disabled {
  background-color: var(--input-bg-color-disabled);
  color: var(--input-font-color-disabled);
}

.FormInputFile .filename {
  padding-left: 0.5rem;
  overflow-y: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: calc(100% - 2rem);
}

.FormInputFile input[type='file'] {
  display: none;
}

.FormInputFile .icon-area {
  display: flex;
  flex-direction: row;
}

.FormInputFile .uploader .clearFiles {
  display: none;
}

.FormInputFile .uploader:hover .clearFiles {
  display: block;
}

.FormInputFile .uploading {
  fill: var(--button-bg-color);
  animation: uploading-icon-rotate 1s infinite linear;
}

@keyframes uploading-icon-rotate {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}

.FormInputFile .uploader .attachIcon {
  fill: var(--button-bg-color);
}

.FormInputFile .uploader.disabled .attachIcon {
  fill: var(--button-bg-color-disabled);
}

.FormInputFile .uploader.basic-error-input-border {
  border: 0.09rem solid var(--input-frame-color-error);
  border-radius: 0.2rem;
}