.document-registration-dialog .detail-area {
  margin-top: 0.5rem;
}

.document-registration-dialog .upload-button {
  margin-top: 1rem;
}

.document-registration-dialog .download-button {
  margin-top: 1rem;
}