.SimplePageNavigation {
  width: 10rem !important;
  padding: 0.5rem 0;
  display: flex;
}

.SimplePageNavigation .space {
  margin: 0 3rem;
}

.SimplePageNavigation .disabled-svg-icon {
  opacity: 0.3;
}
