.ForumThread {
  padding: 0.5rem;
  margin: 0.5rem 1rem;
  border: 1px solid var(--dialog-frame-color);
  background-color: var(--accent-bg-color);
  border-radius: 3px;
  box-sizing: border-box;
  transition-duration: 150ms;
  transition-property: margin, border, box-shadow;
  display: flex;
  flex-direction: column;
}

.ForumThread.active {
  border: 1px solid var(--accent-font-color);
}

.ForumThread .thread-info .IconButton {
  font-size: 0.7rem;
  background-color: var(--listreloadbutton-bg-color);
  color: var(--listreloadbutton-font-color);
  fill: var(--listreloadbutton-font-color);
  transition-property: opacity;
}
.ForumThread .thread-info .IconButton:hover {
  opacity: 0.7;
}

.ForumThread .header .thread-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2em;
}

.ForumThread .header .label {
  font-size: 0.7em;
  font-weight: bold;
  color: var(--viewer-icon-color);
  background-color: var(--titlelabel-font-color);
  padding: 0.25em 1em;
  border-radius: 4px;
  display: inline-block;
}

.ForumThread .header .title {
  color: var(--titlelabel-font-color);
  fill: var(--titlelabel-font-color);
  font-size: 0.9rem;
  width: 100%;
  margin-bottom: 0.4rem;
  word-break: break-all;
  line-height: 1.2rem;
}

.ForumThread .header .title .IconButton {
  width: 1.2em;
  margin-left: 0.3em;
}
.ForumThread .header .title .IconButton svg {
  width: 100%;
  height: auto;
}
.ForumThread .header .title-edit {
  margin-bottom: 1rem;
}
.ForumThread .header .title-edit .actions {
  display: flex;
  justify-content: center;
}
.ForumThread .header .title-edit .actions .CaptionButton {
  width: 6.5em;
  margin-left: 0.5em;
}

.ForumThread .menu-wrapper {
  position: relative;
}
.ForumThread .menu-wrapper .IconButton {
  font-size: 0.7rem;
}
.ForumThread .menu-bg {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 98;
}
.ForumThread .menu-list {
  position: absolute;
  right: 0;
  min-width: 8em;
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
  z-index: 99;
}
.ForumThread .menu-list li {
  padding: 0 0.4em;
  line-height: 2.89em;
  display: flex;
  align-items: center;
  background-color: var(--menu-bg-color);
  cursor: pointer;
}
.ForumThread .menu-list li:hover {
  background-color: var(--menu-bg-color-hover);
}
.ForumThread .menu-list li svg {
  margin-right: 0.4em;
}

.ForumThread .comment-list-container {
  margin-top: 0.5rem;
  max-height: calc(100vh - 32rem); /* 諸々引いた値とする */
  overflow: auto;
}
.ForumThread .comment-list {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

.ForumThread .comment-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9rem;
  height: 3.5em;
  resize: vertical;
}
.ForumThread .comment-input.reply {
  padding-top: 2.5rem;
  height: 6rem;
}

.ForumThread .reply-comment {
  display: flex;
  position: absolute;
  top: 2.8rem;
  padding: 0 0.5rem;
  width: 100%;
  box-sizing: border-box;
}
.ForumThread .reply-comment .reply-detail {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-bg-color);
  border-radius: 2px;
  width: calc(100% - 1rem);
}
.ForumThread .reply-comment .reply-detail > svg {
  width: 1rem;
  height: 1rem;
  background-color: var(--button-bg-color);
  fill: var(--button-font-color);
}
.ForumThread .reply-comment .reply-detail > p {
  font-size: var(--basic-small-font-size);
  width: calc(100% - 1rem);
  margin: 0;
  padding: 0 0.25rem;
  box-sizing: border-box;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.ForumThread .reply-comment .cancel-reply,
.ForumThread .reply-comment .cancel-reply > svg {
  width: 1rem;
  height: 1rem;
}

.ForumThread .comment-form {
  border-bottom: 1px solid #c8c8c8;
  padding: 1rem 0;
  position: relative;
}

.ForumThread .comment-form .action {
  text-align: right;
}

.ForumThread .comment-form .action .CaptionButton {
  width: 6.5em;
}

.ForumThread .threadOpen,
.ForumThread .threadClose {
  font-size: 0.8rem;
  width: auto;
  align-self: flex-end;
  padding-bottom: 0;
}
