.FormBuilder .form-page-tab {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 0.5rem 0.5rem;
  margin-left: 1.5rem;
  width: 16rem;
  color: var(--button-font-color);
  background-color: var(--button-bg-color-hover);
  border: 1px solid var(--titlelabel-font-color);
  border-bottom: none;
  box-sizing: border-box;
}

.FormBuilder .deletePage {
  margin-left: 1rem;
}

.FormBuilder .form-page-tab .ErrorMessage {
  display: none;
}

.FormBuilder .page-list-container {
  max-height: 43dvh;
  overflow-y: auto;
}

.FormBuilder .page-list-footer {
  border-top: 1px solid var(--input-font-color);
  padding: 0.5rem 0.5rem;
}

.FormBuilder .page-list-footer .CaptionButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 0.7rem 0rem;
  border-radius: 0.3rem;
  background-color: var(--button-bg-color);
  line-height: 1;
  color: var(--button-font-color);
  fill: var(--button-font-color);
  cursor: pointer;

  transition-duration: 150ms;
  transition-property: background-color, color, fill;
}

.FormBuilder .form-page-add:hover {
  background-color: var(--button-bg-color-hover);
}