.BlueprintBlueprintList .Dialog .field {
  max-width: 33.333%;
  width: 100%;
}

.BlueprintBlueprintList .Dialog .field > div {
  max-width: 12rem;
}

.BlueprintBlueprintList .Dialog .inputfields.group {
  margin: 2rem 0 0 0;
}

.BlueprintBlueprintList .Dialog .field-abreast input {
  max-width: 3.8rem;
}

.BlueprintBlueprintList .Dialog .field.textarea {
  max-width: 100%;
  padding-right: calc(33.333% - 12rem);
  box-sizing: border-box;
}

.BlueprintBlueprintList .Dialog .field.textarea > div {
  max-width: 100%;
  padding-right: 0.5rem;
}

.BlueprintBlueprintList .field.fileUpload > div,
.BlueprintBlueprintList .Dialog .fileUpload {
  max-width: 100%;
}

.BlueprintBlueprintList .fileUpload .item-name {
  margin: 0;
}

.BlueprintBlueprintList .FileUploader .fileName {
  padding: 0 1rem 0 0;
}

.BlueprintBlueprintList .button-margin {
  margin: 0 0.5rem;
}
