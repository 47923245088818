.SimpleListView {
  position: relative;
}

.SimpleListView .scrollable-area {
  overflow: auto;
}

.SimpleListView .list-header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
  fill: var(--listheader-font-color);
  min-height: 3rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;
  min-width: fit-content;

  position: sticky;
  top: 0;
  z-index: 1;
}

.SimpleListView .list-header>div {
  margin: 0 0.5em;
}

.SimpleListView .IconButton {
  font-size: 0.7rem;
  transition-property: opacity;
}

.SimpleListView .list-header .IconButton:hover {
  opacity: 0.7;
}

.SimpleListView .list-items {
  list-style: none;
  padding-inline: 0;
  margin-block: 0;
  min-width: fit-content;
}

.SimpleListView .list-items .check {
  max-width: 80px;
}

.SimpleListView .Checkbox .margin {
  margin: 0 0 0.5rem 0;
}

.SimpleListView .check .Checkbox {
  display: flex;
  justify-content: center;
}

.SimpleListView li {
  min-height: 2.5rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;
}

.SimpleListView li a {
  text-decoration: underline;
  cursor: pointer;
}

.SimpleListView li span,
.SimpleListView li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SimpleListView li:nth-child(even) {
  background-color: var(--record1-bg-color);
  color: var(--record1-font-color);
}

.SimpleListView li:nth-child(odd) {
  background-color: var(--record2-bg-color);
  color: var(--record2-font-color);
}

.SimpleListView li .cell {
  padding: 0.5rem;
  position: relative;
  display: grid;
}

.SimpleListView .list-footer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  min-height: 3rem;
  background-color: var(--footer-bg-color);
  border-top: 1px solid var(--footer-border-color);

  position: sticky;
  bottom: 0;
  z-index: 1;
}

.SimpleListView .list-footer .total {
  margin-right: 0.5em;
}

.SimpleListView .list-footer .download {
  width: auto;
  font-size: 0.8em;
  text-decoration: underline;
  cursor: pointer;
}
