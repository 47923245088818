.ConditionTreeDialog {
  font-size: 0.8rem;
}
.ConditionTreeDialog .CategorizedCheckList .category-name {
  padding: 0.2rem;
}
.ConditionTreeDialog .CategorizedCheckList .CategorizedBranchCheckbox {
  margin: 0;
}
.ConditionTreeDialog .CategorizedCheckList .CategorizedTipCheckbox {
  margin: 0;
}
.ConditionTreeDialog .CategorizedCheckList .icon > svg {
  width: 18px;
  height: 18px;
}
.ConditionTreeDialog
  .CategorizedCheckbox
  input[type='checkbox']
  + label::before {
  width: 16px;
  height: 16px;
}
.ConditionTreeDialog
  .CategorizedCheckbox
  input[type='checkbox']:checked
  + label::after {
  width: 14px;
  height: 6px;
}
.ConditionTreeDialog .CategorizedCheckList .checklist-area {
  /* ダイアログの最大高 - ダイアログヘッダ高 - ダイアログpadding - 検索エリア高 - 下部ボタンエリア高(ざっくり) */
  max-height: calc(85vh - 4rem - 2rem - 4.5rem - 5.5rem);
  overflow-y: auto;
}

/* ラジオボタンと検索ワード */
.ConditionTreeDialog {
  position: relative;
}
.ConditionTreeDialog .Radio {
  display: inline-block;
  position: absolute;
  margin-top: 1.5rem;
}
.ConditionTreeDialog .Radio > label {
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
}
.ConditionTreeDialog .Radio > label > input {
  margin-right: 0.2rem;
}
.ConditionTreeDialog .CategorizedCheckList .search-area {
  justify-content: flex-end;
  align-items: flex-end;
}
.ConditionTreeDialog .CategorizedCheckList .TextBox .main-area {
  height: 2rem;
  margin-bottom: 0.5rem;
}
.ConditionTreeDialog .CategorizedCheckList .TextBox input {
  font-size: 0.8rem;
}
.ConditionTreeDialog .CategorizedCheckList .TextBox .ErrorMessage {
  display: none;
}
.ConditionTreeDialog .CategorizedCheckList .search-button {
  margin-bottom: 0.8rem;
}

/* ボタンエリア */
.ConditionTreeDialog .button-area {
  justify-content: flex-end;
  align-items: flex-end;
}
.ConditionTreeDialog .button-area > *:not(:first-child) {
  margin-left: 1rem;
}
.ConditionTreeDialog .button-area .button:first-child {
  margin-right: 0;
}
.ConditionTreeDialog .button-area {
  margin-top: 1rem !important;
}

/* 条件解除ボタン */
.ConditionTreeDialog.reset-condition-button {
  color: var(--link-font-color);
  text-decoration: underline;
}
.ConditionTreeDialog.reset-condition-button:hover {
  opacity: 0.7;
}

/* 対象企業数・再計算ボタン */
.ConditionTreeDialog .fetch-count-container {
  display: flex;
  flex-direction: column;
}
.ConditionTreeDialog .fetch-count-label {
  margin-bottom: 0.2rem;
}
.ConditionTreeDialog .fetch-count-button {
  border: 1px solid var(--input-frame-color);
  border-radius: 0;
}
.ConditionTreeDialog .fetch-count-button:hover {
  opacity: 0.7;
}
.ConditionTreeDialog .fetch-count-button.re-calc {
  color: var(--link-font-color);
  text-decoration: underline;
}
