.Arrow {
  position: absolute;
  z-index: 1;
  display: block;
  pointer-events: auto;
  background-color: var(--basic-line-color);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: 0.6;
}
.Arrow.active {
  opacity: 1;
}
