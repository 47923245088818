.IconButton {
  width: 2em;
  padding: 0;
  border: none;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  vertical-align: text-bottom;
  cursor: pointer;

  transition-duration: 150ms;
  transition-property: fill, color;
}
.IconButton svg {
  vertical-align: text-bottom;
}

.IconButton.midium svg {
  height: 28px;
  width: 28px;
  vertical-align: text-bottom;
}

.IconButton.large svg {
  height: 32px;
  width: 32px;
  vertical-align: text-bottom;
}

.IconButton.high {
  fill: var(--highbutton-bg-color);
  color: var(--highbutton-bg-color);
}
.IconButton.high:hover {
  fill: var(--highbutton-bg-color-hover);
  color: var(--highbutton-bg-color-hover);
}

.IconButton.basic {
  fill: var(--button-bg-color);
  color: var(--button-bg-color);
}
.IconButton.basic:hover {
  fill: var(--button-bg-color-hover);
  color: var(--button-bg-color-hover);
}

.IconButton.sub {
  fill: var(--subbutton-bg-color);
  color: var(--subbutton-bg-color);
}
.IconButton.sub:hover {
  fill: var(--subbutton-bg-color-hover);
  color: var(--subbutton-bg-color-hover);
}

.IconButton.danger {
  fill: var(--deletebutton-bg-color);
  color: var(--deletebutton-bg-color);
}
.IconButton.danger:hover {
  fill: var(--deletebutton-bg-color-hover);
  color: var(--deletebutton-bg-color-hover);
}

.IconButton.cancel {
  fill: var(--backbutton-bg-color);
  color: var(--backbutton-bg-color);
}
.IconButton.cancel:hover {
  fill: var(--backbutton-bg-color-hover);
  color: var(--backbutton-bg-color-hover);
}

.IconButton[disabled],
.IconButton[disabled]:hover {
  cursor: default;
  fill: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
}
