.DataView .disabled-svg-icon {
  opacity: 0.3;
}

.ListView .burgermenu .menu-list li a.disabled-menu-item {
  color: var(--actionmenu-font-color-disabled);
}

.noscroll .DataView .contents {
  overflow: hidden;
}

.DataView .contents.over-menu-contents .menu-list {
  position: absolute;
  z-index: 11;
  top: auto;
}

.DataView .list-content .menuIcon {
  pointer-events: none;
}

.DataView .burgermenu.menuPos .menu-list {
  top: auto;
  bottom: 0;
}
