.EstEstimateRequestConfirmation .info-body-header {
  padding-bottom: 2rem;
}

.EstEstimateRequestConfirmation .map-upload img {
  width: 16rem;
  height: auto;
  object-fit: contain;
  border: 0.1rem solid;
}

.EstEstimateRequestConfirmation .label-margin {
  margin-bottom: 1rem;
}

.EstEstimateRequestConfirmation
  .SimpleListDialog
  .Dialog
  .simple-list-dialog
  .SimpleListView
  li
  span {
  word-break: break-all;
  white-space: pre-wrap;
}

.EstEstimateRequestConfirmation .selection-area {
  margin-top: 0.8rem;
  line-height: 1;
}

.EstEstimateRequestConfirmation .adoption-checkbox {
  margin-top: 0.7rem;
  /* エラー領域を使用しない前提 */
  margin-bottom: -0.7rem;
}

.EstEstimateRequestConfirmation .change-area {
  margin-top: 0.8rem;
}
