.DetailConfirmationDialog .Dialog .modal-content {
  width: 60%;
  max-width: 60%;
}
.DetailConfirmationDialog .button-area {
  margin-left: 4rem;
}

.DetailConfirmationDialog .detail-number {
  text-align: right;
}

.DetailConfirmationDialog .transunit-name {
  font-weight: bold;
  height: 2.5rem;
  font-size: var(--basic-big-font-size);
}

.DetailConfirmationDialog .confirmation-line {
  height: 3rem;
}

.DetailConfirmationDialog .file-name {
  cursor: pointer;
}
