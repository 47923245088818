.SurveyRequestCatalogInput .file-uploader-button {
  text-align: center;
  display: block;
  border: none;
  width: 8.5rem;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--button-bg-color);
  color: var(--button-font-color);
  line-height: 1;
  cursor: pointer;
  transition-duration: 150ms;
  transition-property: background-color, color, fill;
  height: 1rem
}

.SurveyRequestCatalogInput .file-uploader-button svg {
  display: none;
}

.SurveyRequestCatalogInput .file-area {
  margin-top: 0.8rem;
}

.SurveyRequestCatalogInput .mixed-input-line [class*='w-']:has(.Checkbox) {
  /* チェックボックスは、テキスト入力のラベル分の余白を上に持つ */
  margin-top: 0.8rem;
  align-self: center;
}
.SurveyRequestCatalogInput .mixed-input-line [class*='w-']:has(.CaptionButton) {
  /* ボタンは、エラーメッセージエリア分の余白を下に持つ */
  margin-bottom: calc(1.5rem - 0.8rem);
  align-self: center;
}

.SurveyRequestCatalogInput .checkbox-line {
  min-height: 3rem;
}
