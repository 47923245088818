.CategorizedBranch input[type='checkbox'] {
  width: 0;
  height:0;
  opacity: 0;
  outline:none;
}
.CategorizedBranch.level-0 > .category-label-area {
  background-color: var(--group-bg-color);
  margin: 0.5rem 0;
}
.CategorizedBranch.level-1 > .category-label-area {
  background-color: var(--menu-bg-color);
  margin: 0.5rem 0;
}
.CategorizedBranch .category-name {
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 0.2rem;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}
.CategorizedBranch input[type='checkbox']:focus-visible + .category-name,
.CategorizedBranch input[type='checkbox']:focus + .category-name {
  outline: var(--basic-font-color) dashed 1px;
}
.CategorizedBranch .category-name > .icon {
  transition-duration: 100ms;
}
.CategorizedBranch.close .category-name > .icon {
  transform: rotate(-90deg);
}
.CategorizedBranch .category-children {
  margin: 0 0 0 2rem;
}
.CategorizedBranch.close .category-children {
  display: none;
}

/* カテゴリ自身が検索ワードに一致していたら通常色とする */
.CategorizedBranch.matched {
  color: var(--basic-font-color);
}
/* 内包するチェックボックスについて、いずれも検索ワードに一致していなければ非活性色とする */
.CategorizedBranch.unmatched:has(.CategorizedCheckbox.unmatched):not(:has(.CategorizedCheckbox.matched)) {
  color: var(--basic-bg-color-disabled);
}
/* 内包するチェックボックスについて、いずれかが検索ワードに一致していれば通常色とする */
.CategorizedBranch.unmatched:not(:has(.CategorizedCheckbox.unmatched)):not(:has(.CategorizedCheckbox.matched)) {
  color: var(--basic-font-color);
}
