.PopUpTabList {
  position: relative;
}
.PopUpTabList .tab-labels {
  display: flex;
}
.PopUpTabList .tab {
}
.PopUpTabList .tab .label {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 11em;
  background-color: var(--group-bg-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.2em 0.5em;
  margin-right: 2em;
  cursor: default;
}

.PopUpTabList .tab-contents {
  position: absolute;
  width: 100%;
}

.PopUpTabList .item-list {
  list-style: none;
  margin: 0;
  padding: 0.5em;
  border: 1px solid var(--group-bg-color);
  background-color: var(--input-bg-color);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 9em;
  align-content: flex-start;
  align-items: flex-start;
}
.PopUpTabList .item-list.hide {
  display: none;
}
.PopUpTabList .item-list > li {
  margin: 0.3em 2em 0.3em 0.3em;
}
.PopUpTabList .item-list > li > a {
  text-decoration: underline;
  cursor: pointer;
}
.PopUpTabList .item-list > li > a:hover {
  opacity: 0.7;
}
