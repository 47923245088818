.Select {
  position: relative;
}

.Select .single::after {
  content: '';
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  width: 0.4rem;
  height: 0.4rem;
  border-top: 2px solid var(--input-icon-color);
  border-left: 2px solid var(--input-icon-color);
  pointer-events: none;
  transform: translateY(-50%) rotate(-135deg);
}
