.EstEstimateResultConfirmation .info-body-header {
  padding-bottom: 2rem;
}

.EstEstimateResultConfirmation .map-upload img {
  width: 14rem;
  height: auto;
  object-fit: contain;
  border: 0.1rem solid;
}

.EstEstimateResultConfirmation .label-margin {
  margin-bottom: 1rem;
}

.EstEstimateResultConfirmation .mb-2 {
  margin-bottom: 2rem;
}

.EstEstimateResultConfirmation .mb-6 {
  margin-bottom: 6rem;
}

.EstEstimateResultConfirmation .ta-right {
  text-align: right;
}

.EstEstimateResultConfirmation .decline-checkbox .Checkbox .margin {
  margin-top: 0.7rem;
  /* エラー領域を使用しない前提 */
  margin-bottom: -0.7rem;
}
