.EstEstimateRequestInput .attachment-area {
    margin-top: 1.0rem;
    display: flex;
}

.EstEstimateRequestInput .attachment-area .attached-checkbox {
    margin-top: 0.7rem;
    width: 10rem;
}

.EstEstimateRequestInput .attachment-area .delete-icon {
    margin-top: 0.5rem;
    width: fit-content;
}

.EstEstimateRequestInput .attachment-area .upload-button {
    width: fit-content;
}