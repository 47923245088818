
.Accordion .accordion-title {
  position: relative;
}
.Accordion .accordion-title::after {
  content: '';
  position: absolute;
  top: 0;
  left: 19.79rem;
  bottom: 0;
  height: 0.045rem;
  width: calc(100% - 19.79rem);
  margin: auto;
  background-color: var(--titlelabel-font-color);
}

.Accordion .accordion-title-label {
  position: relative;
  max-width: 14.89rem;
  width: 100%;
  padding: 0.85rem 0.85rem 0.85rem 4rem;
  color: var(--header-font-color);
  line-height: 1;
  border: 1px solid var(--titlelabel-font-color);
  border-radius: 2.6rem;
  cursor: pointer;
}
.Accordion .accordion-title-label::before {
  content: '';
  position: absolute;
  top: -0.1rem;
  left: 1.2rem;
  bottom: 0;
  margin: auto;
  height: 0.6rem;
  width: 0.6rem;
  border-left: 2px solid var(--titlelabel-font-color);
  border-bottom: 2px solid var(--titlelabel-font-color);
  transform: rotate(-45deg);
}
.Accordion.close .accordion-title-label::before {
  top: 0.1rem;
  transform: rotate(135deg);
}

.Accordion .accordion-body {
  margin-left: 1.5rem;
}
.Accordion.close .accordion-body {
  display: none;
}
