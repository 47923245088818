.TaskListIconView {
  position: relative;
  display: flex;
  align-items: center;
}

.TaskListIconView.bell .unread-indicator {
  position: absolute;
  top: 0.1em;
  left: 1.5em;
  z-index: 1;
  width: 12px;
  height: 12px;
  color: #fff;
  background-image: linear-gradient(#ff6854, #ff2200cc);
  background-clip: padding-box;
  border: 2px solid var(--color-header-bg);
  border-radius: 50%;
}
