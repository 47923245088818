.CompanyConfirmation .navgate {
  padding: 0.8rem;
}

.CompanyConfirmation .head-navgate {
  display: flex;
}

.CompanyConfirmation .head-navgate .iconBox:first-child {
  padding: 0 4rem 0 0;
}

.CompanyConfirmation .disabled-svg-icon {
  opacity: 0.3;
}
