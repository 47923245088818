.FileList {
  display: flex;
  margin-top: 1.8rem;
}

.FileList > .IconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.9rem;
  padding: 0.5em;
  background-color: var(--fileviewer-list-header-bg-color);
  color: var(--fileviewer-list-font-color);
  fill: var(--fileviewer-list-font-color);
  border-radius: 15px 0 0 15px;
  cursor: pointer;
}
.FileList .list-container {
  transition-duration: 300ms;
  transition-property: opacity, width, height;
  overflow: hidden;
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.FileList .list-container.show {
  opacity: 1;
  width: calc(100% - 2.5rem);
  height: 100%;
}

.FileList .header {
  display: flex;
  background-color: var(--fileviewer-list-header-bg-color);
  color: var(--fileviewer-list-font-color);
  fill: var(--fileviewer-list-font-color);
  justify-content: space-between;
  padding: 0 0.5rem;
  line-height: 2.89rem;
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FileList .header .action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
}
.FileList .header .action .IconButton:disabled {
  opacity: 0.5;
}

.FileList .list {
  list-style: none;
  padding-inline-start: 0;
  margin-block: 0;
  overflow-y: auto;
}

.FileList .list li {
  line-height: 2.89rem;
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FileList .list .item {
  border-bottom: 0.1rem solid;
  background-color: var(--fileviewer-list-contents-bg-color);
  color: var(--fileviewer-list-font-color);
  padding: 0 0.5rem 0 1rem;
  overflow: hidden;
  cursor: pointer;
}

.FileList .list .item:hover {
  background-color: var(--fileviewer-list-contents-bg-color-hover);
}

.FileList .list .item.selected {
  background-color: var(
    --fileviewer-list-contents-bg-color-selected
  );
  cursor: default;
}
