.formInputInformation .header-icon-area {
  margin-bottom: 1rem;
}

.formInputInformation .header-icon-area .headerIcons {
  gap: 0;
}

.formInputInformation .form-name {
  font-weight: 700;
}

.formInputInformation .information-area {
  padding-left: 2rem;
}

.formInputInformation .information-area .item-group-100 {
  align-items: flex-end;
}

.formInputInformation .information-area textarea.notice {
  min-height: 8rem;
}
