.BuildedForm .tabPanel {
  cursor: pointer;
}

.BuildedForm .form-area {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  padding: 1rem 0 2rem 2rem;
}

.BuildedForm .form-item {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0;
  margin: 0 1rem;
  min-height: 2rem;
}

.BuildedForm .form-item-size-1 {
  width: calc(25% - 1rem * 2);
}

.BuildedForm .form-item-size-2 {
  width: calc(50% - 1rem * 2);
}

.BuildedForm .form-item-size-3 {
  width: calc(75% - 1rem * 2);
}

.BuildedForm .form-item-size-4 {
  width: calc(100% - 1rem * 2);
}

.BuildedForm .form-item-size-textarea {
  width: calc(75% - 1rem * 2);
  margin-right: calc(25% - 1rem * 2);
}

.BuildedForm .form-item-header {
  margin: -1.5rem -1.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.BuildedForm .form-id {
  font-size: 0.5rem;
}

.BuildedForm .form-item-size-textarea textarea {
  width: calc(100% - 1rem);
  height: 6rem;
  resize: vertical;
}