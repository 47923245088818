.ImportDialog .group {
  margin: 3rem;
}

.ImportDialog .label {
  margin: 0.3rem 0;
}

.ImportDialog .right {
  margin: 0 0 0 auto;
}

/* インポートダイアログのサイズを調整する */
.Dialog .import-dialog {
  width: 80% !important;
  max-width: 80% !important;
}