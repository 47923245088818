.BusinessUnitManagementConfirmation .tabPanelContents .tabPanel {
  width: 8%;
  min-width: 6rem;
}

.BusinessUnitManagementConfirmation .tab-info-body {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

.BusinessUnitManagementConfirmation .contact-list-area {
  height: 14rem;
}

.BusinessUnitManagementConfirmation .notification-icon {
  width: 1.1rem;
  height: 1.1rem;
  vertical-align: text-bottom;
  fill: var(--input-icon-color-error);
}

.BusinessUnitManagementConfirmation .confirmBtn {
  margin-top: 1rem;
}

.BusinessUnitManagementConfirmation .line-up {
  margin-top: 0.8rem;
}

.BusinessUnitManagementConfirmation .information-area {
  margin-top: 2rem;
}

.BusinessUnitManagementConfirmation .document-list-area {
  height: 28rem;
}

.BusinessUnitManagementConfirmation .sortBtn {
  min-width: 14rem;
  width: 14rem;
}

.BusinessUnitManagementConfirmation .management-item-list-area {
  height: 18rem;
}

.BusinessUnitManagementConfirmation .sort-link-area {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
}

.BusinessUnitManagementConfirmation .sort-label {
  margin-left: 0.5rem;
}

.BusinessUnitManagementConfirmation .sort-link {
  text-decoration: underline;
  color: var(--link-font-color);
  background-color: transparent;
  cursor: pointer;
}

.BusinessUnitManagementConfirmation .company-rank {
  font-weight: bold;
}

.BusinessUnitManagementConfirmation p.company-rank {
  font-weight: bold;
  margin-bottom: 0;
}

.BusinessUnitManagementConfirmation .pt-0 {
  padding-top: 0;
}
