.surveyRemarksArea {
  gap: 1rem;
}

.memoEdit {
  height: 3rem
}

.surveyRequestListArea {
  margin-bottom:3rem;
}

.iconButtonArea {
  gap: 0;
}

.surveyRequestIconButton {
  width: 2rem !important;
}

.doubleColon::after {
  content: ":";
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}
