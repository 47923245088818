.bireport-area {
  display: flex;
  margin-bottom: 2rem;
}

.url-area {
  margin-top: 0.2rem;
}

.ragio-label {
  font-weight: bold;
}
