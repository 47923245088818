.LinkTextbox .main-area{
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--input-bg-color-disabled);
}

.LinkTextbox .text-area {
  padding: 0.5rem
}

.LinkTextbox .icon-area {
  padding: 0.5rem
}



