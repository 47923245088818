.IfmFormDefinitionInput .form-builder-area {
  position: relative;
  padding: 2rem 1rem 0rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--input-font-color);
  box-sizing: border-box;
}

.IfmFormDefinitionInput .area-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  font-weight: bold;
  background-color: var(--input-font-color);
  color: var(--input-bg-color);
}

.IfmFormDefinitionInput .usable-area {
  padding: 1.5rem 0 0 0;
}