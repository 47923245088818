.SectionView {
  margin: 0;
  padding: 0.5em;
  font-size: var(--basic-font-size);
  border-bottom: 1px solid var(--pulldown-frame-color);
  position: relative;
  min-height: 2rem;
}

/* 簡易のローディングスタイル */
.SectionView .loading {
  position: absolute;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff7;
}
