.CommentPaneContainer {
  display: grid;
  align-items: start;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  transition-duration: 300ms;
}

.CommentPaneContainer.open-file {
  grid-template-columns: 16rem 1fr 2.5rem;
}

.CommentPaneContainer.open-comment {
  grid-template-columns: 2.5rem 1fr 24rem;
}
