.ContactRegistrationDialog .Dialog .modal-content {
  width: 65%;
  max-width: 65%;
}

.contact-registration-dialog .notification-user-list-area {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.contact-registration-dialog .add-button {
  padding-top: 0.5rem;
}