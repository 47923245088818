
.ThreadComment p {
  margin: 0 0 0.4rem;
  font-size: 0.9rem;
  white-space: pre-wrap;
  word-break: break-all;
}

.ThreadComment .comment-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ThreadComment .action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0.5em;
  font-size: 0.75rem;
}
.ThreadComment .action svg {
  width: 1.5em;
  vertical-align: middle;
}

.ThreadComment .metadata {
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.ThreadComment .metadata :nth-child(1) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadComment .metadata :nth-child(2) {
  text-align: right;
}

.ThreadComment .working-icon {
  opacity: 0.5;
  animation-name: working;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 100%;
}

@keyframes working {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}
