.SessionCard {
  padding: 1em;
}

.SessionCard .control-item {
  margin: 0;
  padding: 0 0 0.5em;
}

.SessionCard .control-item .IconWithCaptionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  margin: 0 auto;
  padding: .8em;
  width: 12rem;
}

.SessionCard .control-item .IconWithCaptionButton svg {
  height: 1.2em;
  width: 1.2em;
  margin: 0 0.2em 0 0;
  fill: var(--button-font-color);
}

.SessionCard .control-item .username {
  text-align: center;
}

.SessionCard .control-item .username .aid {
  display: block;
  color: var(--basic-font-color);
}

.policy {
  font-size: var(--basic-small-font-size, 0.7rem);
  padding-bottom: 2em;
}

.SessionCard .item-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.SessionCard .Dialog .modal-content {
  max-width: 50%;
  width: 468px;
  min-width: 460px;
  margin: 0 auto;
}

.SessionCard .Dialog .modal-body {
  padding: 0 3em 3em 3em;
}

.SessionCard .Dialog .modal-content .topping-label {
  font-weight: 400;
}

.SessionCard .error {
  color: var(--input-font-color-error);
}
