.SideMenu {
  visibility: hidden;
  position: fixed;
  top: 2.6em;
  left: 0;
  max-height: calc(100vh - 2.6em);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: var(--menu-bg-color);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  overflow: scroll;
  z-index: 1000;
}

.SideMenu.open {
  visibility: visible;
}

/* スクロールバーを消す */
.SideMenu::-webkit-scrollbar {
  display: none;
}

.SideMenu .company {
  padding: 0 1em 0;
  font-weight: 600;
}

.SideMenu ul,
ol {
  margin: 0;
  padding: 0;
}

.SideMenu menu {
  margin: 0;
  padding: 0;
  padding-bottom: 1em;
}

.SideMenu .menu-title {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 0.5em 1em;
  font-weight: 600;
}

.SideMenu .menu-title svg {
  margin-right: 0.5em;
  fill: var(--basic-font-color);
}

.SideMenu li {
  list-style-type: none;
}

.SideMenu a {
  position: relative;
  display: block;
  padding: 0.6em 1.8em 0.6em 2.8em;
  color: var(--basic-font-color);
  text-decoration: none;
}
.SideMenu a:hover {
  color: var(--button-font-color);
}

.SideMenu a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 0%;
  height: 100%;
  border-radius: 0 4em 4em 0;
  background-color: var(--menu-bg-color-hover);
}

.SideMenu a:hover:before {
  width: 100%;
}

.SideMenu a span {
  position: relative;
}
