.SearchListView .content-box {
  padding: 2rem;
}

.SearchListView .content-box .btns {
  display: flex;
}

.SearchListView .content-box .btns .buttonBox:first-child {
  margin: 0 8rem 0 0;
}

.SearchListView .Dialog .search-dialog {
  max-width: 80%;
  min-width: 45rem;
}