.CertificateRegistrationDialog .Dialog .modal-content {
  width: 60%;
  max-width: 60%;
  min-height: 50%;
}

.certificate-registration-dialog .obtained-checkbox {
  margin-top: 1.5rem;
}

.certificate-registration-dialog .attachment-area {
  margin-top: 0.5rem;
  display: flex;
}

.certificate-registration-dialog .attachment-area .upload-button {
  margin-top: 0.5rem;
}

.certificate-registration-dialog .attachment-area .download-button {
  margin-top: 0.5rem;
}

.certificate-registration-dialog .attachment-area .download-button .CaptionButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
}

.certificate-registration-dialog .attachment-area .attached-checkbox {
  margin: 1rem 0rem 0rem 1rem;
  padding: 0rem;
  border: 0rem;
  min-width: 8rem;
}

.certificate-registration-dialog .attachment-area .delete-icon {
  margin-top: 1rem;
}

.certificate-registration-dialog .obtainPlan-radio {
  margin-top: 1.5rem;
}
