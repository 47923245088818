.BlueprintBlueprintConfirmation .status-box {
  display: flex;
}

.BlueprintBlueprintConfirmation .blockBtn {
  display: flex;
}

.BlueprintBlueprintConfirmation .buttonBox:first-child {
  margin: 0 calc(33.333% - 170px) 0 0;
}

.BlueprintBlueprintConfirmation .button-margin {
  margin: 0 0.5rem;
}
