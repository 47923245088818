.SelectScheduleDialog .Dialog .modal-content {
  width: 80%;
  max-width: 80%;
}

.select-schedule-dialog {
  margin: 2rem;
}

.select-schedule-dialog .button-area {
  margin: 1rem;
}
