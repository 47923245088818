.OcpBusinessUnitProfileConfirmation .tabPanelContents .tabPanel {
  width: 8%;
  min-width: 5rem;
}

.OcpBusinessUnitProfileConfirmation .tabPanelContents {
  margin-bottom: 1rem;
}

.OcpBusinessUnitProfileConfirmation .tab-info-body {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

.OcpBusinessUnitProfileConfirmation .footer-contents .button-label {
  margin-bottom: 0;
}

.OcpBusinessUnitProfileConfirmation .certificate-list-area {
  height: 28rem;
}

.OcpBusinessUnitProfileConfirmation .sort-link-area {
  display: flex;
  gap:1.5rem;
  margin-bottom: 0.5rem;
}

.OcpBusinessUnitProfileConfirmation .sort-label {
  margin-left: 0.5rem;
}

.OcpBusinessUnitProfileConfirmation .sort-link {
  text-decoration: underline;
  color: var(--link-font-color);
  background-color: transparent;
  cursor: pointer;
}

