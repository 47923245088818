.ForumThreadForm {
  padding: 0.5rem;
  margin: 0.5rem 1rem;
  border: 1px solid var(--accent-font-color);
  background-color: var(--accent-bg-color);
  border-radius: 3px;
  box-sizing: border-box;
}
.ForumThreadForm .actions {
  display: flex;
  justify-content: flex-end;
}
.ForumThreadForm .actions .CaptionButton {
  width: 6.5em;
  margin-left: 0.5em;
}

.ForumThreadForm .Filterbox .search-box {
  width: 19em;
}
