
.ConfirmationDialog .ListView {
  padding: 0 0 1.1rem;
}

.ConfirmationDialog .message {
  padding: 0 0 2rem;
  white-space: pre-line;
}

.ConfirmationDialog .content-box {
  padding: 2rem;
}

.ConfirmationDialog .modal-content {
  max-width: 45rem;
}

.ConfirmationDialog .content-box .btns {
  display: flex;
}

.ConfirmationDialog .content-box .btns .buttonBox:first-child {
  margin: 0 8rem 0 0 ;
}

