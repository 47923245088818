:root {
  --basic-font-size: 1rem;
  --basic-big-font-size: 1.2rem;
  --basic-small-font-size: 0.7rem;
  --input-error-font-size: 0.7rem;
  --input-box-height: 2.5rem;
  --input-icon-height: 1.5rem;
  --input-icon-width: 1.5rem;
  --header-height: 2.6rem;
  --footer-max-height: 10rem;
  --container-main-contents-padding: 0.5rem;
  --icon-row-padding: 0.2rem;
  --toolbar-width: 5vw;
  --listheader-height: 3.8rem;
  --listheader-icon-height: 1.4rem;
  --listheader-icon-width: 1.4rem;
  --listitem-height: 2.8rem;
  --listitem-icon-height: 1.7rem;
  --listitem-icon-width: 1.7rem;
  --listitem-gap: 2rem;
  --menu-list-max-height: 200px;
  --menu-list-width: 500px;
  --button-line-gap: 2rem;
  --max-z-index: 9999;
  --overlay-z-index: 2000;
  --basic-outline-style: var(--outline-color) auto 1px;
}

:focus-visible {
  /* フォーカスが当たった時の基本スタイル */
  outline: var(--basic-outline-style);
}

/* ダイアログなどの背景 */
::backdrop {
  background-color: var(--basic-bg-color-disabled);
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: var(--basic-font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

/* スクロール時に表示部分のみしかbackground-colorが適用されない問題に対処 */
#back-ground-color-theme {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--basic-bg-color);
  z-index: -1;
}

/* ローディング表示 */
#loading-icon-back-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--basic-bg-color-disabled);
  z-index: var(--overlay-z-index, 2000);
}

#theme {
  color: var(--basic-font-color);
}

a {
  color: var(--link-font-color);
}

/*
　　セレクトボックスの作り直し後に削除
*/
/* ここから */
select {
  position: relative;
  width: 100%;
  appearance: none;
  font-size: var(--basic-font-size);
  border: 0.09rem solid var(--pulldown-frame-color);
  background-color: var(--pulldown-bg-color);
  color: var(--pulldown-font-color);
  border-radius: 0.2rem;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
}

select:disabled {
  border: 0.1rem solid var(--input-frame-color-disabled);
  background-color: var(--input-bg-color-disabled);
  color: var(--input-font-color-disabled);
}
/* ここまで */

/* 入力項目の基本的なボーダー */
.basic-input-border {
  border: 0.09rem solid var(--input-frame-color);
  border-radius: 0.2rem;
}

/* 入力項目のエラー時のボーダー */
.basic-error-input-border {
  border: 0.09rem solid var(--input-frame-color-error);
  border-radius: 0.2rem;
}

/* 入力項目の無効時のボーダー */
.basic-disabled-input-border {
  border: 0.09rem solid var(--input-frame-color-disabled);
  border-radius: 0.2rem;
}

/* 入力欄の左肩に表示されるラベル */
.topping-label {
  position: relative;
  top: 0.4rem;
  left: 0.3rem;
  background: linear-gradient(
    180deg,
    var(--basic-bg-color) 0%,
    var(--basic-bg-color) 55%,
    var(--input-bg-color) 55%,
    var(--input-bg-color) 100%
  );
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/* 入力欄の左肩に表示されるラベル 入力欄無効時 */
.disabled-topping-label {
  background: linear-gradient(
    180deg,
    var(--basic-bg-color) 0%,
    var(--basic-bg-color) 55%,
    var(--input-bg-color-disabled) 55%,
    var(--input-bg-color-disabled) 100%
  );
}

.topping-label-checkbox {
  position: relative;
  top: 0.4rem;
  padding-bottom: 0.2rem;
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/* 必須項目の場合、*を付与 */
.required .topping-label::after,
.required .topping-label-checkbox::after {
  content: '*';
  display: inline-block;
  color: var(--input-font-color-error);
}

.btn-nomal {
  border: none;
  background-color: transparent;
}

.btn-basic {
  border: none;
  background-color: var(--button-color);
}

.btn-basic svg {
  fill: #fff;
}

/* チェックボックスとラジオボタンで左肩に表示されるラベル */
.grouping-label {
  position: relative;
  top: 0.4rem;
  left: 0.3rem;
  background-color: var(--basic-bg-color);
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/*
  react-datepickerの日付選択ダイアログ
  XXX: 日付選択ダイアログがz-index指定されている他要素と重なってしまうため追加
*/
.react-datepicker-popper {
  z-index: 10 !important;
}
