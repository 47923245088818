.ToolMenu {
  height: calc(100vh - var(--header-height));
  margin: 0;
  position: fixed;
  top: var(--header-height);
  right: 0;
  display: flex;
  background-color: var(--menu-bg-color);
  z-index: 20;
}

/* スクロールバーを消す */
.ToolMenu::-webkit-scrollbar {
  display: none;
}

.ToolMenu .tool-header {
  padding: 0.5rem 1rem;
}

.ToolMenu .tool-header .tool-close {
  width: auto;
}

.ToolMenu .tool-content {
  overflow: scroll;
  overscroll-behavior-y: contain;
  height: calc(100% - 2rem);
}
