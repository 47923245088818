.ToolBar {
  height: inherit;
  width: var(--toolbar-width);
  margin: 0;
  padding: 0;
  top: var(--header-height, 2.6rem);
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--menu-bg-color);
  overflow: scroll;
}

/* スクロールバーを消す */
.ToolBar::-webkit-scrollbar {
  display: none;
}

.ToolBar ul {
  margin: 0;
  padding: 0;
}

.ToolBar menu {
  margin: 0;
  padding: 0;
  padding-bottom: 1em;
}

.ToolBar .IconButton svg {
  fill: var(--tool-icon-color);
}

.ToolBar .IconButton button:disabled {
  cursor: unset;
}

.ToolBar .IconButton:disabled svg {
  fill: var(--tool-disabled-icon-color);
}

.ToolBar li {
  margin: 12px 0px;
  list-style-type: none;
}
