.IconWithCaptionButton {
  border: none;
  min-width: 10rem;
  padding: 0.51rem 0.7rem;
  border-radius: 0.3rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;

  transition-duration: 150ms;
  transition-property: background-color, color, fill;
}
.IconWithCaptionButton .button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconWithCaptionButton .icon {
  width: 1.38em;
}

.IconWithCaptionButton svg {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

.IconWithCaptionButton .caption {
  margin-left: 0.2em;
  white-space: pre-line;
  width: calc(100% - 1.38em);
}

.IconWithCaptionButton.high {
  background-color: var(--highbutton-bg-color);
  color: var(--highbutton-font-color);
  fill: var(--highbutton-font-color);
}
.IconWithCaptionButton.high:hover {
  background-color: var(--highbutton-bg-color-hover);
  color: var(--highbutton-font-color-hover);
  fill: var(--highbutton-font-color-hover);
}

.IconWithCaptionButton.basic {
  background-color: var(--button-bg-color);
  color: var(--button-font-color);
  fill: var(--button-font-color);
}
.IconWithCaptionButton.basic:hover {
  background-color: var(--button-bg-color-hover);
}

.IconWithCaptionButton.sub {
  background-color: var(--subbutton-bg-color);
  color: var(--subbutton-font-color);
  fill: var(--subbutton-font-color);
}
.IconWithCaptionButton.sub:hover {
  background-color: var(--subbutton-bg-color-hover);
}

.IconWithCaptionButton.danger {
  background-color: var(--deletebutton-bg-color);
  color: var(--deletebutton-font-color);
  fill: var(--deletebutton-font-color);
}
.IconWithCaptionButton.danger:hover {
  background-color: var(--deletebutton-bg-color-hover);
}

.IconWithCaptionButton.cancel {
  background-color: var(--backbutton-bg-color);
  color: var(--backbutton-font-color);
  fill: var(--backbutton-font-color);
}
.IconWithCaptionButton.cancel:hover {
  background-color: var(--backbutton-bg-color-hover);
}

.IconWithCaptionButton[disabled],
.IconWithCaptionButton[disabled]:hover {
  cursor: default;
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
  fill: var(--button-font-color-disabled);
}
