.HistoryDialog .Dialog .modal-content {
  width: 80%;
  max-width: 85%;
  min-height: 50%;
}

.HistoryDialog .scrollable-area {
  padding: 1rem 1rem 0;
  overflow: auto;
}

.HistoryDialog .history-header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
  fill: var(--listheader-font-color);
  min-height: 3rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;

  position: sticky;
  top: 0;
  z-index: 1;
}
.HistoryDialog .history-header > div {
  margin: 0 0.5rem;
}

.HistoryDialog .history-list {
  list-style: none;
  padding-inline: 0;
  margin-block: 0;
  overflow: auto;
  max-height: 50vh;
}

.HistoryDialog li {
  min-height: 2.5rem;
  display: grid;
  align-items: center;
  box-sizing: border-box;
}
.HistoryDialog li:nth-child(even) {
  background-color: var(--record1-bg-color);
  color: var(--record1-font-color);
}
.HistoryDialog li:nth-child(odd) {
  background-color: var(--record2-bg-color);
  color: var(--record2-font-color);
}

.HistoryDialog li .cell {
  padding: 0.7rem 0.5rem;
  position: relative;
  display: grid;
  white-space: pre-line;
}

.HistoryDialog .button-area {
  margin-left: 2rem;
}
