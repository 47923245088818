.Tooltip {
  pointer-events: none;
  position: fixed;
  z-index: 3;
  padding: 0.5rem;
  font-size: var(--basic-small-font-size);
  line-height: 1.6rem;
  color: var(--input-font-color);
  overflow-wrap: break-word;
  border-radius: 0.3em;
  background: var(--input-frame-color);
  min-width: 3.25rem;
  white-space: pre-line;
}
.Tooltip:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 1.875rem;
  border: 15px solid transparent;
  border-top: none;
  border-bottom: 15px solid var(--input-frame-color);
  margin-left: -15px;
}
