.SurveyRequestReceiverConfirmation .area-container {
  margin-bottom: 2rem;
}

.SurveyRequestReceiverConfirmation .strong {
  font-weight: bolder;
}

.SurveyRequestReceiverConfirmation .underline {
  text-decoration: underline;
}

.SurveyRequestReceiverConfirmation .right {
  text-align: right;
}

.SurveyRequestReceiverConfirmation .label-margin {
  margin-bottom: 1rem;
}


.SurveyRequestReceiverConfirmation .notification-area.close {
  display: none;
}

.SurveyRequestReceiverConfirmation .notification-display-area {
  background-color: var(--input-bg-color);
  padding: 1rem;
  min-height: 3rem;
  margin: 1rem 0 1rem 1.5rem;
  color: var(--input-font-color-error);
  white-space: pre-wrap;
}
