.ForumThreadComment {
  position: relative;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.ForumThreadComment.our {
  margin-left: 1rem;
  background-color: var(--record1-bg-color);
  border-radius: 0.75rem;
  border-bottom-right-radius: 0;
}
.ForumThreadComment.their {
  margin-right: 1rem;
  background-color: var(--record2-bg-color);
  border-radius: 0.75rem;
  border-top-left-radius: 0;
}

.ForumThreadComment .delete-button {
  float: right;
  font-size: 0.6rem;
  margin-top: -0.2rem;
  margin-right: -0.2rem;
}
.ForumThreadComment .delete-button > svg {
  width: 100%;
  height: 100%;
}

.ForumThreadComment > p {
  margin: 0 0 0.4rem;
  font-size: 0.9rem;
  white-space: pre-wrap;
  word-break: break-all;
}

.ForumThreadComment .reply-detail {
  font-size: var(--basic-small-font-size);
  margin-left: 0.25rem;
  border-left: 0.25rem solid var(--button-bg-color);
  padding-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.ForumThreadComment .comment-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ForumThreadComment .action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0.5em;
  font-size: 0.75rem;
}
.ForumThreadComment .action svg {
  width: 1.5em;
  vertical-align: middle;
}

.ForumThreadComment .metadata {
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-end;
}
.ForumThreadComment .metadata :nth-child(1) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ForumThreadComment .metadata :nth-child(2) {
  text-align: right;
}

.ForumThreadComment .working-icon {
  opacity: 0.5;
  animation-name: working;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 100%;
}

@keyframes working {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
