/*
　tenant独自のcss定義
　admin,tenant共有のcssはshared/css/index.cssに定義
 */

/* TODO: 削除予定 ⬇️ここから⬇️ */
.inputfields {
  display: flex;
}

.inputfields .long {
  width: calc(100% - 37.83%);
  margin: 0 4.5% 0 0;
}

.inputfields .small {
  margin: 0 4.5% 0 0;
  width: 12rem;
}

.inputfields .middle {
  width: 28.83%;
  margin: 0 4.5% 0 0;
}

.inputfields .minor {
  width: 18%;
  padding: 0 2.5% 0 0;
}

.inputfields .minor:last-child {
  padding: 0;
}

.inputfields .minor input {
  width: 14.38%;
}

.inputfields .minor [disabled] {
  line-height: 1.4rem;
}

.inputfields .minor-cover {
  width: calc(100% - 23.33%);
}

.inputfields.long > div {
  width: 16rem;
}

.inputfields > div {
  margin: 0 2rem 0 0;
  width: 12rem;
}

.inputfields .no-spece {
  padding: 0;
}

.inputfields.textarea > div {
  width: 100%;
  flex: 1;
}

.inputfields textarea {
  min-height: 7.04rem;
  min-width: 100%;
  line-height: 1.7;
}

.inputfields .required .topping-label::after {
  content: '*';
  display: inline-block;
  color: var(--input-font-color-error);
}
/* TODO: 削除予定 ⬆️ここまで⬆️ */

.input-areatitle {
  font-size: var(--basic-font-size);
  color: var(--header-font-color);
  font-weight: bold;
  margin: 0 0 2rem;
  padding: 2rem 0 0.4rem 0;
  border-bottom: 1px solid var(--basic-line-color);
}

.input-blocktitle {
  position: relative;
  max-width: 14.89rem;
  width: 100%;
  padding: 0.85rem 0.85rem 0.85rem 4rem;
  color: var(--header-font-color);
  line-height: 1;
  border: 0.07rem solid var(--titlelabel-font-color);
  border-radius: 2.6rem;
}

.input-blocktitle::before {
  content: '';
  position: absolute;
  top: -0.1rem;
  left: 1.2rem;
  bottom: 0;
  margin: auto;
  height: 0.6rem;
  width: 0.6rem;
  border-left: 2px solid var(--titlelabel-font-color);
  border-bottom: 2px solid var(--titlelabel-font-color);
  transform: rotate(-45deg);
}

.close .input-blocktitle::before {
  top: 0.1rem;
  transform: rotate(135deg);
}

.input-blocktitle-outer {
  position: relative;
}

.input-blocktitle-outer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 19.79rem;
  bottom: 0;
  height: 0.045rem;
  width: calc(100% - 19.79rem);
  margin: auto;
  background-color: var(--titlelabel-font-color);
}

.input-blockbody.close {
  display: none;
}

.input-blockbody.indent {
  margin-left: 1.5rem;
}

/* 常に最下部に固定される操作エリア */
.footer {
  min-height: 6rem;
  max-height: var(--footer-max-height, 10rem);
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--toolbar-width));
  margin: auto;
  background-color: var(--footer-bg-color);
  box-shadow: 0 -0.1rem var(--footer-border-color);
  /*
  横幅でボタン溢れる場合はスクロール
  display:flexにする場合は以下を削除
  */
  overflow-x: auto;
}

.footer > .footer-contents {
  width: 85vw;
  margin: 0.8rem auto;
}

/* svgアイコンの無効時表現 */
.disabled-svg-icon {
  opacity: 0.3;
}

/* 汎用コメントダイアログのテキストエリア */
.Dialog .modal-content .comment-confirm-daialog .Textarea textarea {
  min-height: 4.8rem;
  max-width: 32.75rem;
  width: 100%;
}

/* 汎用コメントダイアログのフッター */
.Dialog .modal-content .comment-confirm-footer {
  padding: 1em 0 0;
  justify-content: center;
  display: flex;
}

/* 汎用コメントダイアログのボタン */
.Dialog .modal-content .comment-confirm-daialog .btn {
  width: 12em;
  margin: 0 0.4rem;
}

/* 汎用コメントダイアログの幅 */
.Dialog .modal-content:has(.comment-confirm-daialog) {
  max-width: 45rem;
}

/* 汎用ダイアログのテキストエリア */
.Dialog .modal-content .confirm-daialog .Textarea textarea {
  min-height: 4rem;
  max-width: 32rem;
  width: 100%;
  line-height: 3rem;
}

/* 汎用ダイアログのフッター */
.Dialog .modal-content .confirm-footer {
  padding: 1em 0 0;
  justify-content: center;
  display: flex;
}

/* 汎用ダイアログのボタン */
.Dialog .modal-content .confirm-daialog .btn {
  width: 12em;
  margin: 0 0.4rem;
}

/* 汎用ダイアログの幅 */
.Dialog .modal-content:has(.confirm-daialog) {
  max-width: 45rem;
}

/* レイアウト統一用のデザイン */

/* 入力行 */
.input-line {
  display: flex;
  gap: 0 var(--listitem-gap, 2rem);
}

.input-line > div:not(.item-group),
.input-line > div:not(.item-group) > div {
  width: 20rem;
}

/*
  入力グループ
    - 要素幅を`%`にて指定するためのグループ
    - class名は`item-group-<number>`(<number>はグループ要素幅（%）を指定)
*/
.input-line > div[class^='item-group-'],
.input-line > div[class*=' item-group-'] {
  display: flex;
  gap: inherit;
  flex-shrink: 0;
}

.item-group-100 {
  flex-basis: 100%;
}

/* 要素幅(`%`指定） */
.input-line > div[class^='item-group-'] > div.w-100,
.input-line > div[class*=' item-group-'] > div.w-100 {
  flex-basis: calc(100% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-80,
.input-line > div[class*=' item-group-'] > div.w-80 {
  flex-basis: calc(80% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-77,
.input-line > div[class*=' item-group-'] > div.w-77 {
  flex-basis: calc(77% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-75,
.input-line > div[class*=' item-group-'] > div.w-75 {
  flex-basis: calc(75% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-66,
.input-line > div[class*=' item-group-'] > div.w-66 {
  flex-basis: calc(66.666% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-60,
.input-line > div[class*=' item-group-'] > div.w-60 {
  flex-basis: calc(60% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-50-nogap,
.input-line > div[class*=' item-group-'] > div.w-50-nogap {
  flex-basis: calc(50%);
  flex-shrink: 0;
  width: 0rem;
}

.input-line > div[class^='item-group-'] > div.w-50,
.input-line > div[class*=' item-group-'] > div.w-50 {
  flex-basis: calc(50% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-40,
.input-line > div[class*=' item-group-'] > div.w-40 {
  width: calc(40% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-33,
.input-line > div[class*=' item-group-'] > div.w-33 {
  width: calc(33.333% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-25,
.input-line > div[class*=' item-group-'] > div.w-25 {
  width: calc(25% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-23,
.input-line > div[class*=' item-group-'] > div.w-23 {
  width: calc(23% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-20,
.input-line > div[class*=' item-group-'] > div.w-20 {
  width: calc(20% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-16,
.input-line > div[class*=' item-group-'] > div.w-16 {
  width: calc(100% / 6 - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-12,
.input-line > div[class*=' item-group-'] > div.w-12 {
  width: calc(12% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-25-icon,
.input-line > div[class*=' item-group-'] > div.w-25-icon {
  width: calc(25% + 30px - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

/* テキストエリア幅(`%`指定） */
.input-line > div[class^='item-group-'] textarea.w-100,
.input-line > div[class*=' item-group-'] textarea.w-100 {
  width: calc(100% - calc(var(--listitem-gap, 2rem) / 2));
}

.input-line > div[class^='item-group-'] textarea.w-80,
.input-line > div[class*=' item-group-'] textarea.w-80 {
  width: calc(80% - var(--listitem-gap, 2rem) / 2);
}

.input-line > div[class^='item-group-'] textarea.w-75,
.input-line > div[class*=' item-group-'] textarea.w-75 {
  width: calc(75% - var(--listitem-gap, 2rem) / 2);
}

.input-line > div[class^='item-group-'] textarea.w-50,
.input-line > div[class*=' item-group-'] textarea.w-50 {
  width: calc(50% - var(--listitem-gap, 2rem) / 2);
}

div[class^='item-group-'] > div,
div[class*=' item-group-'] > div,
div[class^='item-group-'] > div[class^='w-'] > div,
div[class*=' item-group-'] > div[class^='w-'] > div,
div[class^='item-group-'] > div[class*=' w-'] > div,
div[class*=' item-group-'] > div[class*=' w-'] > div,
div[class^='item-group-'] .TextBox input,
div[class*=' item-group-'] .TextBox input,
div[class^='item-group-'] > div[class^='w-'] .TextBox input,
div[class*=' item-group-'] > div[class^='w-'] .TextBox input,
div[class^='item-group-'] > div[class*=' w-'] .TextBox input,
div[class*=' item-group-'] > div[class*=' w-'] .TextBox input {
  /*
    XXX: 入力コンポーネントの`div`及び`input`要素に width: 100%; を指定していないとサイズが変更されない。
    各入力コンポーネントがレスポンシブに対応できるようになった場合は以下の`width: 100%;`の指定は不要になる想定
  */
  width: 100%;
}

/* テキストエリア（高さ） */
div[class^='item-group-'] textarea.mh-short,
div[class*=' item-group-'] textarea.mh-short,
div.item-group textarea.mh-short {
  min-height: 2rem;
}

div[class^='item-group-'] textarea.mh-normal,
div[class*=' item-group-'] textarea.mh-normal,
div.item-group textarea.mh-normal {
  min-height: 4rem;
}

div[class^='item-group-'] textarea.mh-middle,
div[class*=' item-group-'] textarea.mh-middle,
div.item-group textarea.mh-middle {
  min-height: 6rem;
}

div[class^='item-group-'] textarea.mh-high,
div[class*=' item-group-'] textarea.mh-high,
div.item-group textarea.mh-high {
  min-height: 8rem;
}

/* ボタン行 */
.button-line {
  display: flex;
  gap: 0 var(--button-line-gap, 2rem);
}

/* input系のエラー表示判定用ダミークラス */
.visible-error-message {
}

/* 通知エリア */
.notification-area.close {
  display: none;
}

.notification-display-area {
  background-color: var(--input-bg-color);
  padding: 1rem;
  min-height: 3rem;
  margin: 1rem 0 1rem 1.5rem;
  color: var(--input-font-color-error);
  white-space: pre-wrap;
}

/* タブ制御 */
.tabPanelContents {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1em solid var(--titlelabel-font-color);
  padding-left: 1.5rem;
}

.tabPanelContents .tabPanel {
  width: 10rem;
  padding: 0.5rem 0.3rem;
  text-align: center;
  border: 0.1em solid var(--titlelabel-font-color);
  border-bottom: 0rem;
}

.tabPanelContents .tabPanel.current {
  color: var(--button-font-color);
  background-color: var(--button-bg-color);
}

.scroll-main-contents-area {
  padding: 1rem 0rem 0rem 0rem;
}
