.HomeMainArea {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3rem;
}

.HomeMainArea .visual-area {
  padding: 1.5rem 0;
}

.HomeMainArea .visual-area .logo img {
  max-width: 6em;
  width: 2em;
  fill: var(--accent-icon-color);
}

.HomeMainArea .visual-area .font {
  display: inline-block;
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--accent-font-color);
  line-height: 1;
}

.HomeMainArea .company-name-area {
  padding-top: 2rem;
  font-size: 2.4rem;
  font-weight: bold;
}
