.CompanySearch {
  /* 全体的にコンパクトにとのことでフォントを若干小さめに指定 */
  font-size: 0.9rem;
}

.CompanySearch .section-head {
  font-size: var(--basic-font-size);
  border-left: 0.5rem solid var(--basic-line-color);
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.CompanySearch .condition-section {
  padding: 0 1rem 1rem;
}

/* 前提条件 */
.CompanySearch .Checkbox:has(.precondition) .main-contents {
  display: inline-flex;
  flex-direction: column;
  width: 20rem;
}
.CompanySearch .Checkbox:has(.precondition) .main-contents > div {
  padding: 0.4rem 0.5rem;
  border: 1px var(--input-frame-color) solid;
  background-color: var(--input-bg-color);
  box-sizing: border-box;
}
.CompanySearch .Checkbox:has(.precondition) .main-contents > div:not(:last-child) {
  border-bottom: none;
}
.CompanySearch .Checkbox:has(.precondition) .ErrorMessage {
  display: none;
}

/* 目的から探す */


/* 詳細条件 */
.CompanySearch .detail-condition {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}
.CompanySearch .detail-condition dt {
  width: 9rem;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: var(--group-bg-color);
  border-bottom: 1px solid var(--input-frame-color);
}
.CompanySearch .detail-condition dd {
  width: calc(100% - 10em);
  margin: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: var(--input-bg-color);
  border-bottom: 1px solid var(--input-frame-color);
}
.CompanySearch .condition-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.CompanySearch .condition-list-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--input-frame-color);
}
.CompanySearch .condition-list-item-actions > *:not(:last-child) {
  margin-right: 0.5rem;
}
.CompanySearch .multiple-type-label {
  font-weight: bold;
}
.CompanySearch .condition-list-item-actions {
  margin: 0.5rem 0;
}
.CompanySearch .condition-list-item-actions > a {
  text-decoration: underline;
  cursor: pointer;
}
.CompanySearch .condition-list-item-actions > a:hover {
  opacity: 0.7;
}

.CompanySearch .condition-list-item-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.CompanySearch .condition-list-item-detail .ConditionCheckbox {
  padding: 0.5rem;
  width: 10rem;
}
.CompanySearch .condition-add-button {
  color: var(--button-bg-color);
  fill: var(--button-bg-color);
  border-radius: 0;
}
.CompanySearch .condition-add-button:hover {
  opacity: 0.7;
}
.CompanySearch .sales-scale-selector {
  display: flex;
}
.CompanySearch .sales-scale-selector .sales-scale {
  width: 8rem;
}
.CompanySearch .sales-scale-selector .suffix {
  margin: 0.8rem 0 0 0.5rem;
}
.CompanySearch .TextBox:has(.keyword) .ErrorMessage {
  display: none;
}
.CompanySearch .sales-scale .ErrorMessage {
  display: none;
}
.CompanySearch .input-with-unit {
  display: flex;
  align-items: center;
}
.CompanySearch .input-with-unit > span {
  text-wrap: nowrap;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.CompanySearch .sizeUnit {
  margin-right: 0.2rem;
}
.CompanySearch .Radio:has(.sizeUnit) label {
  margin-right: 1rem;
}
.CompanySearch .Radio:has(.sizeUnit) label:last-child {
  margin-right: 0;
}
.CompanySearch .Radio:has(.sizeUnit) .ErrorMessage {
  display: none;
}

/* アクション */
.CompanySearch .footer {
  display: flex;
  align-items: center;
}
.CompanySearch .footer-contents > * {
  margin-right: 1rem;
}
.CompanySearch .count-label {
  font-weight: bold;
}
.CompanySearch .fetch-count-button {
  border: 1px solid var(--input-frame-color);
  border-radius: 0;
}
.CompanySearch .fetch-count-button:hover {
  opacity: 0.7;
}
.CompanySearch .fetch-count-button.re-calc {
  color: var(--link-font-color);
  text-decoration: underline;
}

.CompanySearch .fetch-reset-button {
  color: var(--link-font-color);
  text-decoration: underline;
}
.CompanySearch .fetch-reset-button:hover {
  opacity: 0.7;
}
