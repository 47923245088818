.BlueprintBlueprintSearchList .search-text-box {
  display: flex;
  max-width: 28rem;
}

.BlueprintBlueprintSearchList .search-text-box .btn-box {
  display: flex;
  align-items: flex-start;
  padding: 1rem 0 0 0;
}

.BlueprintBlueprintSearchList .search-text-box .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -4px 0 0 0;
  height: 2.6rem;
}
