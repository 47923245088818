.Textarea .width-fit {
  width: 95%;
  height: 6rem;
}

.Textarea textarea[disabled] {
  color: var(--input-font-color-disabled);
  background-color: var(--input-bg-color-disabled);
}

.Textarea textarea {
  resize: vertical;
  padding: 0.5rem;
  background-color: var(--input-bg-color);
  color: var(--input-font-color);
}
