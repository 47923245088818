.pageNavigation {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 1.5rem;
}

.customIconArea {
  display: flex;
  margin-right: 2rem;
}

.back-button {
  margin-right: 2.5rem;
}

.updated-info,
.issuer-info {
  position: absolute;
  right: 0;
  font-size: 14px;
  bottom: 0;
  margin-right: 2rem;
}