.ExtendFileUploader {
  position: relative;
}

.ExtendFileUploader input[type='file'] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.ExtendFileUploader .file-chooser:has(input[type='file']:focus-visible) {
  outline: var(--basic-outline-style);
}

.ExtendFileUploader .file-chooser {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 0.7rem 0rem;
  border-radius: 0.3rem;
  background-color: var(--button-bg-color);
  line-height: 1;
  color: var(--button-font-color);
  fill: var(--button-font-color);
  cursor: pointer;
  border: none;

  transition-duration: 150ms;
  transition-property: background-color, color, fill;
}

.ExtendFileUploader .disabled .file-chooser,
.ExtendFileUploader .disabled .file-chooser:hover {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
}

.ExtendFileUploader .file-chooser svg {
  height: 1rem;
}

.ExtendFileUploader .file-chooser:hover {
  background-color: var(--button-bg-color-hover);
}

.ExtendFileUploader .dnd-area {
  display: flex;
  box-sizing: border-box;
  background-color: var(--file-upload-dnd-bg-color);
  min-height: 10rem;
  padding: 0.5rem;
  width: 100%;
}

.ExtendFileUploader .dnd-area .FileUploaderSelectParts {
  text-align: center;
  margin: auto;
}

.ExtendFileUploader .dnd-area .file-chooser {
  margin-left: 0.5rem;
}
