.toast-success {
  background: var(--toast-bg-color);
  color: var(--toast-font-color);
  padding-left: 1.5rem;
  z-index: var(--max-z-index, 9999);
}

.toast-error {
  background: var(--errortoast-bg-color);
  color: var(--errortoast-font-color);
  padding-left: 1.5rem;
  z-index: var(--max-z-index, 9999);
}

.toast-warning {
  background: var(--warningtoast-bg-color);
  color: var(--warningtoast-font-color);
  padding-left: 1.5rem;
  z-index: var(--max-z-index, 9999);
}