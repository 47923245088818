.FileUploader {
  position: relative;
}

.FileUploader .uploadStatus {
  display: grid;
  grid-template-columns: 5em 2em 1fr;
  padding: 0.5rem 0;
}

.FileUploader input[type='file'] {
  display: none;
}

.FileUploader .file-chooser {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 0.7rem 0rem;
  border-radius: 0.3rem;
  background-color: var(--button-bg-color);
  line-height: 1;
  color: var(--button-font-color);
  fill: var(--button-font-color);
  cursor: pointer;

  transition-duration: 150ms;
  transition-property: background-color, color, fill;
}

.FileUploader .disabled .file-chooser,
.FileUploader .disabled .file-chooser:hover {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-font-color-disabled);
}

.FileUploader .file-chooser svg {
  height: 1rem;
}

.FileUploader .message {
  color: #f00;
  font-size: var(--basic-small-font-size);
}

.FileUploader .status,
.FileUploader .fileName,
.FileUploader .actionIcon {
  display: flex;
  align-items: center;
  font-size: var(--basic-font-size);
}

.FileUploader .center {
  position: relative;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.FileUploaderArea .file-chooser:hover {
  background-color: var(--button-bg-color-hover);
}