.SurveyRequestSenderInput .area-container {
  margin-bottom: 2rem;
}

.SurveyRequestSenderInput .file-area {
  margin-top: 0.8rem;
}

.SurveyRequestSenderInput .file-area.businessUnit {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.SurveyRequestSenderInput .mixed-input-line [class*='w-']:has(.Checkbox) {
  /* チェックボックスは、テキスト入力のラベル分の余白を上に持つ */
  margin-top: 0.8rem;
  align-self: center;
}
.SurveyRequestSenderInput .mixed-input-line [class*='w-']:has(.CaptionButton) {
  /* ボタンは、エラーメッセージエリア分の余白を下に持つ */
  margin-bottom: calc(1.5rem - 0.8rem);
  align-self: center;
}
