.AssetConfirmation .status-box {
  display: flex;
}

.AssetConfirmation .blockBtn {
  display: flex;
}

.AssetConfirmation .blockBtn .buttonBox:last-child {
  margin: 0 0 0 3rem;
}

.AssetConfirmation .button-margin {
  margin: 0 0.5rem;
}
