.SimpleListDialog .Dialog .modal-content {
  width: 80%;
  max-width: 85%;
  min-height: 50%;
}

.SimpleListDialog .Dialog .simple-list-dialog {
  padding: 1rem
}

