.Header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  width: 100vw;
  background-color: var(--header-bg-color);
  color: var(--header-font-color);
  box-shadow: 0 -0.2em 0 var(--header-font-color) inset;
  z-index: 30;
}

[theme='dark'] .Header {
  box-shadow: none;
}

.Header .nav-btn svg {
  fill: var(--header-icon-color);
  cursor: pointer;
}

.Header .nav-btn svg:hover {
  fill: var(--header-icon-color-hover);
}

.Header .symbol {
  padding: 0.4em 0 0;
  font-weight: 600;
}

.Header .header-btns {
  display: flex;
  padding: 0.3em 2.2em 0.5rem 0.6em;
}

.Header .header-btns .iconBox .icon {
  display: flex;
  justify-content: center;
}

.Header .header-btns .IconButton {
  width: 2em;
  margin: 0 0.2em;
  padding: 0.4em 0 0;
}

.Header .header-btns .IconButton svg {
  height: 1.2em;
  width: 1.2em;
  fill: var(--header-font-color);
}

.Header .header-btns .IconButton svg:hover {
  fill: var(--header-font-color-hover);
}

.Header .header-btns .accountCircle svg {
  height: 1.4em;
  width: 1.4em;
}

.Header .btn-outer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header .menu-info {
  position: absolute;
  min-width: 320px;
  top: 2.6em;
  right: 3em;
  background-color: var(--pulldown-bg-color);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
}